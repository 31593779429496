@import "../../styles/variables.scss";

.loginLabel {
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 14px;
}

.inputLabel {
  color: #5ac3f0;
  font-family: Gilroy-Bold;
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 0;
  padding-top: 12px;
  margin-top: 0;
  padding-bottom: 5px;
}

.pillinput {
  margin-top: 10px;
}

.inputNameContainer {
  position: relative
}

.inputSelectContainer {
  width: 50%;
  position: relative
}

.form {
  height: 100%;
}

.title {
  font-size: 24px;
  color: #001698;
  padding-top: 9px;
  text-align: center;
  font-family: Gilroy-Bold;
  font-weight: initial;
  height: 36px;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 140px;
  margin: 0 32px;
}

.inputOption {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 70px;
}

.label {
  color: $textColorLightBlue;
  font-family: Gilroy-Bold;
  font-size: 12px;
  margin-bottom: 0px !important;
  margin-left: 0 !important;
  padding-top: 12px !important;
  margin-top: 0 !important;
  padding-bottom: 5px;

  span {
    font-family: Gilroy-Bold;
    font-weight: initial;
  }
}

.classInput {
  padding: 0 !important;
  padding-bottom: 0px;
  padding-bottom: 0 !important;
  margin-left: 0 !important;
  border-bottom: none !important;
}

.customInputContainer {
  width: 100%;
  padding-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid;
  border-color: rgb(210, 217, 233);
}

.classInputSelect {
  padding: 0 !important;
  margin-left: 0 !important;
  width: 90% !important;
}

.documentOptions {
  font-family: Gilroy-Regular;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 84px;
  button {
    height: 40px;
    border-radius: 12px;
    font-size: 16px;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 147px;
  }
}

.errorMessageStyle {
  margin-left: 0;
}

.updatedBy {
  color: $otpInputsColor;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.updatedBylabel {
  @extend .label;
  color: inherit;
  font-family: Gilroy-Regular;
  font-size: 14px;
}

.updatedByData {
  font-size: 14px;
  font-family: Gilroy-Regular;
}

.listContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding-bottom: 18px;
}

.listContainer:first-of-type {
  margin-top: 10px;
}

.subTitle {
  color: $textColorLightBlue;
  width: 15%;
}

.pillinputContainer {
  width: 80%;
  position: relative;
}

.ErrorMessage {
  position: absolute;
}

.errorMessageStyle {
  margin-left: 0;
}

@media (max-width: 1024px) {
  .title {
    font-size: 20px;
  }
}