.multipleSwitchOptions {
  display: grid;
  grid-template-columns: 50px 30px 50px;
  align-items: center;
  gap: 3px;
  font-family: "Gilroy-Regular";
  text-align: center;
  color: #c4c4c4;
  font-family: Gilroy-Regular;
  font-size: 14px;
}
