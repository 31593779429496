@import "../../styles/variables.scss";

.dashboard {
  margin: 20px;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

