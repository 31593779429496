@import "../../styles/variables.scss";

.overlayBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(23, 23, 23, 0.3);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  animation-name: fadeIn;
  z-index: 1000;
  
}

.overlayBackgroundFilters {
  justify-content: flex-end;
  padding: 2%;
  padding-top: 5%;
  align-items: flex-start;
}

.modalContainer {
  position: relative;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  background-color: $textColorWhite;

  padding: 50px 30px;
}

.modalLarge {
  width: 738px;
  height: 260px;
  padding: 0;
}

.modalMedium {
  width: 680px;
  height: 260px;
}

.modalSmall {
  width: 480px;
  height: 260px;
}

.modalConfirm {
  width: 473px;
  height: 302px;
  min-height: 302px;
  padding: 13px;
}

.modalXS {
  width: 370px;
}

.modalFilters {
  padding: 20px 20px;
  width: 242px;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: $textColorLightBlue $otpInputsColor;
}

/* width */
.modalFilters::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.modalFilters::-webkit-scrollbar-track {
  background: $otpInputsColor;
  margin: 1rem;
}

/* Handle */
.modalFilters::-webkit-scrollbar-thumb {
  background: $textColorLightBlue;
}

/* Handle on hover */
.modalFilters::-webkit-scrollbar-thumb:hover {
  background: rgba(90, 195, 240, 0.5);
}

.closeButton {
  position: absolute;
  top: 25px;
  right: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.closeButtonFilters {
  top: 6px;
  right: 12px;
}

.closeButtonIcon {
  color: $buttonColor;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .modalFilters {
    width: 100%;
  }
}
