@import "../../styles/variables.scss";

// Crear Mixin para los containers 
.patientContainer {
    background-image: $loginPatientsImg;
    @include description;
}

.managerContainer {
    background-image: $managerLoginImg;
    @include description;
}

.otpContainer {
    background-image: $otpPatientsImg;
    @include description;
}

.adminContainer {
    background-image: $adminLoginImg;
    @include description;
}

.onboardingContainer {
    background-image: $onboardingImg;
    @include description;
}

.onboardingRespContainer {
    background-image: $onboardingRespImg;
    @include description;
}

.logoFsfb {
    position: absolute;
    top: -18.75vh;
    right: 0;
    width: 37.5vh;
}

.text {
    color: $textColorWhite;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.text1 {
    font-size: 5.62vh;
    white-space: pre-wrap;
    line-height: 6.25vh;
    padding-left: 5%;
}

.text2 {
    font-size: 2.5vh;
    white-space: pre-wrap;
    margin-top: 4vh;
    padding-left: 5%;
}

.text3 {
    font-size: 1.62vh;
    font-family: Gilroy-Regular;
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 5%;
}

.onboardingText {
    color: $textColorWhite;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 32.12vh;
    align-items: flex-start;
    margin-left: 70%;

    .text1 {
        font-size: 5.62vh;
        white-space: pre-wrap;
        line-height: 6.25vh;
        padding-left: 0;
    }
    
    .text2 {
        font-size: 3.5vh;
        font-family: Gilroy-Regular;
        white-space: pre-wrap;
        margin-top: 0;
        padding-left: 0;
        
    }
    
    .text3 {
        font-size: 2.6vh;
        font-family: Gilroy-Regular;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .onboardingText {
        align-items: start;
        margin-left: 0;
        padding-left: 20px;
        margin-bottom: 26.12vh;
        .text1 {
            font-size: 35px;
        }
        .text2 {
            font-size: 20px;
        }
        .text3 {
            font-size: 16px;
        }
    }
    .logoFsfb {
        right: -145px;
    }
}