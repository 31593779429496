
@import "../../styles/variables.scss";

.lineContainer {
  display: grid;
  grid-template-columns: 9% 89%;
  gap: 25px;
  width: 100%;
  align-items: center;
}

.inputLabel {
  color: $loginInputsColor;
  font-family: 'Gilroy-Bold';
  font-size: 20px;
}

.labelWhite {
  color: white;
  font-family: 'Gilroy-Bold';
  font-size: 20px;
}

@media (min-width: 1367px) {
  .lineContainer {
    grid-template-columns: 10% auto;
    gap: 25px;
  }
  
}