@import "../../styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  left: 2vh;
  position: absolute;
  top: 2vh;
}

.mainTitle {
  color: $primaryColor;
  font-size: 1.75vh;
  margin-left: 1.25vh;

  // fix firefox multiprocess
  font-weight: initial;
}

@media (max-width: 360px) {
  .container {
    display: none;
  }
}
