.tooltip {
  position: absolute;
  top: -10px;
}

.tooltipDesenlaces {
  position: absolute;
  top: 23px;
  left: 9px;
}

.tooltipContigo {
  position: absolute;
  top: 26px;
  left: 73px;
}

.tooltipTrack {
  position: absolute;
  top: 8px;
  left: 73px;
}

.trBackground {
  background-color: transparent !important;
  height: 0px !important;
}

.tooltipPacientes {
  position: absolute;
  top: 10px;
  left: 5px;
}