@import "../../styles/variables.scss";

.formContainer {
  padding: 22px 24px;
  box-sizing: border-box;
}

.patientName {
  color: $primaryColor;
  font-size: 20px;
  text-align: center;
  font-family: Gilroy-Bold;
  font-weight: initial;
}

.updateTitle {
  font-size: 14px;
  line-height: 28px;
  color: $filterLabelModal;
  text-align: center;
  font-family: Gilroy-Regular;
}

.inputContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 31px;
  width: 100%;
  
  label {
    font-family: "Gilroy-Bold";
    font-weight: initial;
    font-size: 12px;
    line-height: 14px;
    color: $textColorLightBlue;
  }
}

.inputWidthSmall {
  width: 141px;
}
.inputWidthLarge {
  width: 256px;
}
