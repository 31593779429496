
@import "../../styles/variables.scss";

.selectNewOption {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
}
.optionContainer{
  width: 100%;
}

.inputOption {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.inputsContainer {
  display: grid;
  grid-template-columns: 90% 10%;
}

.inputWhiteOption {
  width: 120px !important;
}

.npsRange {
  align-self: end;
  color: $otpInputsColor;
  font-family: Gilroy-Regular;
  font-size: 14px;
}


.modalButton{
  font-family: 'Gilroy-Medium';
  font-size: 14px;
  line-height: 23px;
  text-decoration-line: underline;
  color: $loginLabelColor;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}

.checkboxSlider {
  display: flex;
  justify-content: space-between;
}