@import "../../styles/variables.scss";

.container {
  color: $loginInputsColor;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: $textColorWhite;
  border-radius: 16px;
  box-shadow: 0 0 15px rgba(120, 135, 160, 0.5);
  border-spacing: 0;
  padding: 30px 20px 30px 20px;

  table {
    thead {
      font-size: 15px;
      tr {
        height: 22px;
        th:nth-child(1) {
          width: 50%;
        }
        th:nth-child(2) {
          width: 25%;
        }
        th:nth-child(3) {
          width: 25%;
        }
      }
    }
    tr {
      background-color: $textColorWhite !important;
      td {
        font-family: Gilroy-Regular;
      }
    }
  }
}

@media (max-width: 1024px) {
  .container {
    padding-top: 20px;
    table {
      thead {
        font-size: 12px;
      }
    }
  }
}