@import "../../styles/variables.scss";

.loginLabel {
  color: $textColorLightBlue;
  font-family: Gilroy-Bold;
  margin-bottom: 2vh;
  margin-top: 4vh;

  span {
    font-family: Gilroy-Bold;
    font-weight: initial;
  }
}

.form {
  display: grid;
  grid-template-columns: 30% 50% 20%;
  gap: 24px;
  padding: 10px 0 20px;
  align-items: center;
}

.selectOption {
  >div {
    display: grid;
    grid-template-areas: "label value";
    align-items: center;
    gap: 0 20px;
    height: auto;

    label {
      font-size: 12px;
      line-height: 16px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0;
    }

    select {
      font-size: 10px;
      line-height: 16px;
    }

    >div {
      grid-column: 1/-1;
    }
  }
}

.input {
  >div {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: auto;

    label {
      font-size: 12px;
      line-height: 16px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0;
    }

    input {
      font-size: 12px;
      line-height: 16px;
      padding: 0;
    }

    input::placeholder {
      color: $otpInputsColor;
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.buttonSearch {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $buttonColor;
  cursor: pointer;
  gap: 5px;
  display: flex;
  font-size: 12px;
  font-family: Gilroy-Bold;
  line-height: 16px;
}

@media (max-width: 768px) {
  .form {
    flex-direction: column;
  }
}