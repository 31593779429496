@import "../../styles/variables.scss";

.scaleContainer {
  width: 100%;
  max-width: 584px;
  background-color: $textColorLightBlue;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 0px;
  display: grid;
  grid-template-columns: 59px auto;
}

.icon {
  background-color: $primaryColor;
  border-radius: 16px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $textColorWhite;
}

.title {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $textColorWhite;
  width: 100%;
}

.title h2 {
  font-size: 25px;
  line-height: 39px;
  font-family: "Gilroy-Bold";
  line-height: 1.3;
}

@media (min-width: 601px) and (max-width: 960px) {
  .title {
    padding: 5px;

    h2 {
      font-size: 20px;
    }
  }
}

@media (max-width: 600px) {
  .scaleContainer {
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    height: 2.5rem;
  }

  .icon {
    border-radius: 16px;
    height: 100%;
    width: 20%;
  }

  .title {
    padding: 0;
    width: 80%;
    overflow: auto;

    h2 {
      font-size: 16px !important;
      font-weight: initial;
    }
  }
}
