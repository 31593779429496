 @import "../../styles/variables.scss";

.switchContainer {
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowContainer {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.rowContainer:hover {
  background-color: rgba(117, 196, 235, 0.1);
}
