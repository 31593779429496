@import "../../styles/variables.scss";

.central {
  height: 100%;
  color: $loginInputsColor;
  background-image: $logoFsfbBackground;
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 8rem;
  background-size: contain;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 44vh;
  justify-content: space-between;
  margin-top: 2vh;
}
