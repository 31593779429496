@import "../../styles/variables.scss";

body {
  color: $textColorWhite;
}

.splashContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: 100vh;
  background-color: $primaryColor;
}

.label {
  font-weight: bold;
  font-size: 3.25vh;
  font-family: Gilroy-Bold;
  color: $textColorWhite;

  // fix firefox multiprocess
  font-weight: initial;
}

.logoFsfb {
  position: absolute;
  top: -18.75vh;
  right: -18vh;
  width: 37.5vh;
}
