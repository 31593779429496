@import "../../styles/variables.scss";

.npsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1000px;
  margin: auto;
  width: 100%;
}

.npsGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 9.5px;
}

.npsOption {
  width: 9%;
  height: 59px;
  position: relative;
}

.npsValue {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Gilroy-Bold;
  color: $primaryColor;
  font-size: 18px;
}

.npsOption input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 2px solid;
  outline: none;
  margin-right: 5px;
}

.npsOption input[type="radio"]:checked {
  background-color: #000;
}

.rangesSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .npsOption input[type="radio"] {
    border-radius: 10px;
  }
}