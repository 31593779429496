@import "../../styles/variables.scss";


.label {
  color: $loginInputsColor;
  font-family: 'Gilroy-Bold';
  font-size: 13px;
}

.labelWhite {
  color: white;
  font-family: 'Gilroy-Bold';
  font-size: 13px;
}

.textArea {
  border: 1px solid #c4c4c4;
  border-radius: 16px;
  background-color: white;
  // min-height: 137px;
  width: 100%;
  padding: 19px 15px;
  box-sizing: border-box;
  resize: none;
  font-family: "Gilroy-Medium";
  color: $loginInputsColor;
  overflow: hidden;
}

.textArea:focus-visible {
  outline: $buttonColor auto 1px;
}

.numArea {
  width: 280px;
  min-height: 46px;
  height: 46px;
  padding: 13px 5px;
  font-size: 16px;
}

.errorContainer {
  height: 4rem;
}

.errorMessage {
  color: $errorColor;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 1rem 0;
}

.exclamationIcon {
  margin-right: 0.4rem;
}

.textAreaDisabled {
  min-height: 60px;
  height: 60px;
}
