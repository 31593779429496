@import "../../styles/variables.scss";

.container {
    font-family: Gilroy-Regular;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.equipmentSelector {
    display: flex;
    gap: 8px;
}

.observations {
    display: flex;
    gap: 16px;
    align-items: flex-start;
}

.attachmentFile {
    display: flex;
    gap: 10px;
    align-items: center;
}

.textArea {
    border-radius: 8px;
    border: 1px solid $otpInputsColor;
    resize: none;
    outline: none;
    color: $loginInputsColor;
    font-size: 12px;
    line-height: 16px;
    font-family: Gilroy-Regular;
    height: unset;
    padding: 2px;
    width: 100%;
}

.detail {
    font-family: Gilroy-Bold;
    color: $loginInputsColor;
}

.regularDetail {
    color: $loginInputsColor;
}

.requiredField {
    color: $errorColor;
}

.modalType {
    .observations {
        flex-direction: column;
        gap: 2px;
    }

    .textArea {
        background-color: $greyAccordion;
    }
}