@import "../../styles/variables.scss";

.card{
  padding: 0
}

.containerTable {
  padding-top: 10px;
  border: 1px solid;
  border-radius: 11px;
  box-shadow: 0 0 15px rgba(120, 135, 160, 0.5);
  height: 100%;
  background-color: white;
  border-color: transparent;
  width: 100%;
  z-index: 2;
  position: relative;
}

.containerTableNoBorder {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.trackInProcessDesenlaces {
  @include accordionHeader;
  padding-left: 40px;
  span {
    font-family: Gilroy-Regular
  }
}

.trackInProcess {
  @include accordionHeader;
  span {
    font-family: Gilroy-Regular
  }
}

.numberSize {
  font-size: 35px;
  width: 75px;
}


.headerDontDeleted {
  display: flex;
  flex-direction: row;
  gap: 5%;
  width: 100%;
}

.headerFinished, .headerInProcess {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 16px;
  div:nth-child(1) {
    width: 30%;
  }
  div:nth-child(2) {
    width: 45%;
  }
  div:nth-child(3) {
    width: 25%;
    padding-right: 50px;
  }
}

.stopTrackIcon {
  position: absolute;
  right: 44px;
  top: 22px;
  cursor: pointer;
}

.resendTracing{
  position: absolute;
  right: 68px;
  top: 25px;
  cursor: pointer;
}

.trackFinished {
  @include accordionHeader;
  span {
    font-family: Gilroy-Regular
  }
}

.trackPausedDesenlaces {
  @include accordionHeader;
  padding-left: 40px;
  span {
    font-family: Gilroy-Regular
  }
}

.trackPaused {
  @include accordionHeader;
  span {
    font-family: Gilroy-Regular
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

.arrow {
  display: flex;
}

.columnsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 9px;
}

.firstColumnDesenlaces {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.firstColumn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  div:nth-child(1) {
    width: 30%;
  }
  div:nth-child(2) {
    width: 45%;
  }
  div:nth-child(3) {
    width: 25%;
    padding-right: 50px;
  }
}


.lastColumnDesenlaces {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.lastColumn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  div:nth-child(1) {
    width: 30%;
    max-width: 30%;
  }
  div:nth-child(2) {
    width: 70%;
  }
}


.table {
  border-radius: 4px;
  border: 1px solid;
  margin: 0 auto;
  border-spacing: 0;
  width: 100%;
  position: relative;
  border-color: transparent;
}

.thead {
  height: 10%;
  tr {
      height: 3rem;
  }
}

.header {
  background: white;
  color: rgba(40, 40, 40, 1);
  font-family: Gilroy-Bold;
  font-size: 16px;
  // width: 25%;
  height: 35px;

  // fix firefox multiprocess
  font-weight: initial;
}

.scaleNameWidth {
  @extend .header;
  // width: 40% !important;
}

.generalWidth {
  @extend .header;
  width: 20% !important;
}

.headerName {
  height: 100%;
}

.headerLabel {
  width: 100%;
  height: 100%;
}

.tbody {
  height: 90%;
}

.row {
  height: 10%;
}

.row:nth-child(odd) td {
  background-color: $greyAccordion;
}

.row:last-child td:first-child {
  border-radius: 0 0 0 11px
}

.row:last-child td:last-child {
  border-radius: 0 0 11px 0px
}

.cells {
  @include cell;
  padding: 0px 10px;
  box-sizing: border-box;
}

.overflow {
  max-height: 18px;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin:auto ;
    padding-right: 10px;
  padding-left: 10px;
  // white-space: nowrap;
}

.overflow:hover {
  max-height: 100%;
  overflow-y: visible;
  overflow-x: visible;
  white-space: pre-wrap
}

@media (max-width: 1365.9px) {
  .trackPaused, .trackFinished, .trackInProcess {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .headerFinished, .headerInProcess {
    div:nth-child(1) {
      width: 30%;
    }
    div:nth-child(2) {
      width: 40%;
    }
    div:nth-child(3) {
      width: 30%;
      padding-right: 50px;
    }
  }

  .firstColumn {
    div:nth-child(1) {
      width: 30%;
    }
    div:nth-child(2) {
      width: 40%;
    }
    div:nth-child(3) {
      width: 30%;
      padding-right: 50px;
    }
  }
}

@media (max-width: 1000px) {

  .header {
    font-size: 12px;
  }
}

@media (min-width: 1000.1px) and (max-width: 1365.9px) {
  .header {
    font-size: 14px;
  }
}