@import "../../styles/variables.scss";

.titleRadio {
  color: $loginInputsColor;
  font-size: 14px;
}


.labelText {
  font-size: 14px ;
}

.labelNumber {
  font-family: 'Gilroy-Bold';
  font-size: 14px;
}

.line {
  color: #DEDEDE;
  border: solid 1px;
  margin: 24px 0;
}

.optionsRadioContainer{
  display: flex ;
  flex-direction: row ;
  gap: 3px;
  align-items: baseline;
}
.optionsRadioContainer > label {
  margin: 0;
  max-width: 60px;
  width: 100%;
  text-align: center
}

.optionsRadioContainerPosas {
    justify-content: center;
}

.posasRadioContainer {
  margin: 0 !important;
}

.containerWidth {
  width: 100%;
  padding-top: 5%;
}

.labelPosas {
  font-size: 12px;
  font-family: Gilroy-Regular;
}

.labelPosasManager {
  font-size: 12px;
  font-family: Gilroy-Regular;
}

@media (max-width: 960px) {
  .radioContainer {
    margin: 5px !important;
    // width: 1.5rem;
    width: max-content;
    svg {
      width: 1rem
    }
    span {
      padding: 0;
    }
  }
  .posasRadioContainer {
    width: 1.5rem;
    svg {
      width: 1rem
    }
    span {
      padding: 0;
    }
  }
  .eq5dRadioContainer {
    width: auto;
  }
  .labelPosas {
    font-size: 10px;
  }
  .labelPosasManager {
    font-size: 10px;
  }
  .containerWidth {
    width: 100%;
    padding-top: 10%;
  }
  .labelTab {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
  .optionsRadioContainerPosas {
    display: flex ;
    gap: 3px;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .optionsRadioContainer {
    flex-direction: column;
    align-items: center;
  }
  .labelMobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 1024px) {
  .titleRadio {
    font-size: 10px;
  }
  .labelNumber {
    font-size: 10px;
  }
}