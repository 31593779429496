@import "../../styles/variables.scss";

.radioContainer {
    display: flex;
    gap: 8px;
}

.radioRow {
    flex-direction: row;
}

.radioColumn {
    flex-direction: column;
}

.label {
    font-family: Gilroy-Regular;
    color: $loginInputsColor;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.radioBtn {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
}

.customRadioBtn {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-family: arial;
}

.customRadioBtn__showCheckbox {
    background-image: url(../../assets/imgs/icons/unCheckRadio.svg);
    border: none;
}

.radioBtn:checked+.customRadioBtn__showCheckbox {
    background-image: url(../../assets/imgs/icons/checkedRadio.svg);
}

.customRadioBtn__show {
    display: inline-block;
    width: 14.5px;
    height: 14.5px;
    background-size: cover;
}