
@import "../../styles/variables.scss";

.dateTimeContainer {
  display: flex;
  gap: 20px;
}

.title {
  color: $loginInputsColor;
  font-family: 'Gilroy-Bold';
  font-size: 20px;

}

.line {
  color: #DEDEDE;
  border: solid 1px;
  margin: 24px 0;
}

.labelNumber {
  font-family: 'Gilroy-Bold';
  font-size: 14px;
}

@media (max-width: 600px)  {
  .dateTimeContainer  {
    flex-direction: column;
  }
}