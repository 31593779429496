@import '../../styles/variables.scss';

.periodicityListContainer {
  margin-top: 20px;
}

.periodicityList {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 78%;
  margin-left: 20px;
  margin-top: 17px;
}

.lineName{
  font-size: 12px;
  color: $grey;
  font-family: Gilroy-Regular;
}

.dateOriginTitle {
  font-size: 12px;
  font-family: Gilroy-Regular;
  line-height: 16.792px;
  margin-top: 3px;
  text-align: center;
  max-width: 25%;
}

.periodicityOption {
  grid-column: 1/3;
  margin-top: 40px;
}

.textFrecuency {
  font-size: 12px;
  font-family: Gilroy-Regular;
  line-height: 16.792px;
  text-align: center;
}
