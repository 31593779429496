@import "../../styles/variables.scss";

.sectionCard {
  background-color: #5AC3F0;
  box-shadow: 0px 0px 7.85404px rgba(0, 0, 0, 0.25);
  padding: 0;
  margin: auto;
}

.noSectionCard {
  @extend .sectionCard;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.arrowAccordion {
  right: 14px;
  top: 16px;
}

.sectionCardContainer {
  min-height: 54px;
  display: flex;
  align-items: center;
}

.titleSectionAccordion {
  padding-left: 31.5px;
  font-size: 20px;
  font-weight: lighter;
  color: $textColorWhite;
  line-height: 25px;
}

.questionDetails {
  width: 90%;
  margin: 0 auto;
  box-shadow: none;
}

.scaleOptionContainer {
  background-color: white;
  padding: 26px 22px;
}

.scaleOptionContainer:nth-child(1) {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.scaleOptionContainer:nth-last-child(1) {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

@media (max-width: 1024px) {
  .questionDetails {
    width: 95%;
  }
}