@import "../../styles/variables.scss";

.listForms {
  margin-top: 15px;
}

.titleForm {
  color: $textColorLightBlue;
  font-size: 12px;
  line-height: 16px;
  font-family: Gilroy-Bold;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 10px;

  >div:last-child {
    border-right: none;
  }
}

.listOption {
  border: none;
  border-right: 2px solid $primaryColor;
  cursor: default;
  font-size: 12px;
  color: $loginInputsColor;
  padding: 0 8px 0 0;
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Gilroy-Regular;
  line-height: 16px;
}

.deleteValue {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: $primaryColor;
  font-weight: bold;
}