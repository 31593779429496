@import '../../styles/variables.scss';

.alertContainer {
  box-sizing: border-box;
  padding: 25px 0px;
  color: $grey;
}
.cards {
  padding: 25px;
  box-sizing: border-box;
}
.title {
  font-size: 18px;
  color: $primaryColor;
}

.subtitle {
  font-size: 14px;
  color: $textColorLightBlue;
}

@media (max-width: 1024px) {
  .title {
    font-size: 14px;
    color: $primaryColor;
  }
  
  .subtitle {
    font-size: 12px;
    color: $textColorLightBlue;
  }
}

