@import "../../styles/variables.scss";

.selectContainer {
    border: 1px solid $primaryColor;
    border-radius: 8px;
    height: 18px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
}

.icon {
    margin-right: 4px;
}

.placeholder,
.content {
    color: $primaryColor;
    font-size: 10px;
    line-height: 16px;
    font-family: Gilroy-Bold;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.content {
    font-family: Gilroy-Regular;
}

.optionsContainer {
    background-color: white;
    border-radius: 8px;
    box-shadow: 1px 0px 6.31px 0px #00000033;
    padding: 10px 0;
    z-index: 10;
    position: absolute;
    margin-top: 4px;

    max-height: 152px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: $greyAccordion;
    }

    .option:nth-child(2n-1) {
        background-color: $greyAccordion;
    }
}

.option {
    color: $grey;
    cursor: pointer;
    font-size: 10px;
    font-family: Gilroy-Regular;
    line-height: 16px;
    padding: 3px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}