@import "../../styles/variables.scss";

.updateTitleCustom {
  margin-top: 0;
}

.helpTextCustom {
  margin-top: 23px;
  margin-bottom: 20px;
}
