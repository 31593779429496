@import "../../styles/variables.scss";
.descriptionContainer {
  font-family: Gilroy-Regular;
  font-size: 14px;
  width: 100%;
  color: $loginInputsColor;
  width: 90%;
  padding: 2vh 3vh;
  margin: 0 auto;
  gap: 1%;
  border-radius: 16px;
  box-shadow: 0 0 15px rgba(120, 135, 160, 0.5);
  border-spacing: 0;
  height: 30%;
  margin-bottom: 4vh;
}

.label_1 {
  font-family: Gilroy-Bold;
  font-size: 20px;
  font-weight: initial;
  margin-bottom: 2vh;
}

.description {
  margin-bottom: 2vh;
}

.label_2 {
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-weight: initial;
  margin-bottom: 2vh;
}

.listDescription {
  font-size: 16px;
  list-style-position: inside;
  li {
    line-height: 18px;
  }
}

@media (max-width: 1024px) {
  .descriptionContainer {
    width: 95%;
  }
}
