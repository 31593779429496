@import "../../styles/variables.scss";

.selectoption {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.selectContainer {
  font-family: Gilroy-Regular;
  color: $loginInputsColor;
  border: none;
  border-bottom: 1px solid;
  border-color: rgba(210, 217, 233, 1);
  padding: 1.2vh;
  outline-color: $textColorLightBlue;
  width: 100%;
  background-color: $textColorWhite;
    // Arrow
  background-image: url(../../assets/imgs/icons/arrowSelect.svg);
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  padding-right: 20px;
}

.selectContainer_Lines {
  font-family: Gilroy-Regular;
  color: $loginInputsColor;
  border: none;
  border-bottom: 1px solid;
  border-color: rgba(210, 217, 233, 1);
  padding: 1vh;
  outline-color: $textColorLightBlue;
  width: 100%;
  background-color: $textColorWhite;

    // Arrow
  background-image: url(../../assets/imgs/icons/arrowSelect.svg);
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.option{
  font-family: Gilroy-Regular;
}

.label {
  color: $textColorLightBlue;
  font-family: Gilroy-Bold;
  // font-size: 2vh;
  margin-bottom: 2vh;
  margin-top: 4vh;
  max-width: 240px;
  margin-left: 1vh;
}

.servicesLine {
  margin-top: 0,
}

// .error {
//   // margin-left: 35px;
//   margin-top: 0;
//   margin-bottom: 0;
// }