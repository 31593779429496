@import "../../styles/variables.scss";

$paddingSpaceMin: 38px;
$paddingSpaceMax: 46px;

.parent {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  &.isLinkDropped {
    & .space {
      width: 18px;
      &.isMinor {
        width: 18px;
      }
    }
  }
  &.card {
    padding: 11% 0 11% 25%;
    flex-grow: 1;
    margin-left: 10%;
    &.isMinor {
      margin-left: 30%;
      padding: 11% 0 11% 25%;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
  &.cardDropdown {
    padding: 5% 0 5% 5%;
    flex-grow: 1;
    margin-left: 0;
    &.isMinor {
      padding: 5% 0 5% 5%;
      margin-left: 10px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }
  &.isSelected {
    background-color: white;
    &.isMinor {
      background-color: $otpInputsColor;
    }
  }
}

.space {
  width: $paddingSpaceMax;
  &.isMinor {
    width: calc($paddingSpaceMax + 10px);
  }
}
