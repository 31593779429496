@import "../../styles/variables.scss";

.buttonOption {
  background-color: transparent;
  border: none;
  color: rgba(0, 157, 172, 1);
  font-family: "Gilroy-Regular";
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 1rem;
  cursor: pointer;
  z-index: 1;
  position: relative;
  height: 20px;
  font-size: 14px;
}

.buttonOption:disabled {
  border: "none";
  color: $grey;
}

.buttonOption:hover {
  border: "none";
}

.width {
  width: 3vh;
}
