@import "../../styles/variables.scss";

.formContainer {
  width: 300px;
  position: relative;
}

.backButton {
  background: none;
  border: none;
  color: $textColorWhite;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  left: -30vw;
  top: -30px;
}
.textInfo {
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.3%;
  margin-top: 30px;
  span {
    font-family: Gilroy-Bold;
  }
}

.inputContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.buttonContainer {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.doctor {
  position: absolute;
  top: 10px;
  right: -270px;
}

.spinnerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(120, 135, 160, 0.5);
  border-radius: 16px;
  top: 0;
  left: 0;
}

@media (max-width: 600px) {
  .doctor {
    display: none;
  }
  .formContainer {
    margin-top: 70px;
  }

  .backButton {
    top: 0;
    left: 0;
    font-size: 12px;
  }
}
