@import "../../styles/variables.scss";

.error {
  color: $errorColor;
  font-size: 1.62vh;
  font-weight: bold;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: initial;
}