@import '../../styles/variables.scss';

.listContainer{
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-bottom: 18px;
}

.listContainer:first-of-type {
  margin-top: 10px;
}

.title {
  color: $textColorLightBlue;
  width: 15%;
}

.pillinputContainer {
  width: 85%;
  position: relative;
}

.ErrorMessage {
  position: absolute;
}