@import "../../styles/variables.scss";

.tablesContainer {
  width: 96%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 90px;
}

.closeOptopion {
  position: absolute;
  width: 20px;
  background-color: transparent;
  border: none;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.alertIcon {
  width: 18px;
  height: 18px;
}

.namePlan {
  cursor: pointer;
}

.frequencyDays {
  font-size: 12px;
  line-height: 16px;
}

.frequencyPeriod {
  color: #DEDEDE;
  font-size: 10px;
  line-height: 18px;
}