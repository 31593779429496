@import "../../styles/variables.scss";


.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.logo {
  width: 123px;
}
.headerTitle {
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 18px;
  font-weight: 400;
  padding-left: 7px;
}

.headerPrincipal {
  border-left: 1px solid $primaryColor;
  color: $primaryColor;
}

.headerLight{
  border-left: 1px solid $textColorWhite;
  color: $textColorWhite;
}