@import "../../styles/variables.scss";

.option {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 12%;
  right: 2%;
}
.dateLabel {
  color: $buttonColor;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.3;
}

.headerLabel {
  width: 100%;
  height: 100%;
}

.headerSorted {
  width: 0;
  display: inline-block;
}

.pagination {
  margin-top: 2vh;
  bottom: 0px;
  position: relative;
}


.btnsContainer {
  display: flex;
  flex-direction: row;
}


.arrowSort {
  // position: absolute;
  top: 7px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.tabsContainer {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.draftCopyBtn {
  width: 50%;
  height: inherit;
  color: $otpInputsColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid;
  border-color: $otpInputsColor;
  cursor: pointer;
}

.finalizedBtn {
  @extend .draftCopyBtn;
}

.selectedBtn {
  color: $buttonColor;
  border-color: $primaryColor;
}

.rowContainer {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.rowContainer:hover {
  background-color: rgba(117, 196, 235, 0.1);
}

@media (max-width: 1000px) {
  .dataLabelContainer {
    width: 5rem;
  }
  .dateLabel {
    font-size: 10px;
  }
  .arrowSort {
    top: 3px;
  }
}

@media (min-width: 1367px) {
  .dateLabel {
    font-size: 14px;
  }
  .arrowSort {
    top: 50%;
    width: 0.6rem;
  }
}

@media (min-width: 2000px) {
  .arrowSort {
    top: 50%;
    width: 0.8rem;
  }
}

@media (max-height: 768px) {
  .tabsContainer {
    height: 35px;
    margin-bottom: 5px;
    font-size: 14px;
  }
}
