@import "../../styles/variables.scss";



.container {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  grid-template-rows: 50px auto;
  grid-template-areas:
  "helpText helpText"
  "formSection imgContainer";
}

.helpText {
  color: $loginInputsColor;
  font-family: Gilroy-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  padding: 24px 0 0 0;
  grid-area: helpText;
}

.formSection {
  display: flex;
  flex-direction: column;
  grid-area: formSection;
}

.imgContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    max-height: 90vh;
    position: sticky;
    top: 0;
    width: 100%;
    grid-area: imgContainer;
    .img{
      width:100%;
      max-height: 615px;
    }
}