@import "../../styles/variables.scss";

.container {
  width: 295px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.logo {
  width: 123px;
}

.title {
  color: $primaryColor;
  text-align: center;
  font-family: Gilroy-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardForm {
  border-radius: 20px;
  background-color: $textColorWhite;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 11px 0px rgba(120, 135, 160, 0.25);
  margin-top: 35px;
  padding: 36px auto;
  position: relative;
  width: 100%;
}

.doctor {
  position: absolute;
  top: -35px;
  right: -270px;
  width: 330px;
}

@media (max-width: 600px) {
  .container {
    width: 90%;
    height: 70vh;
    justify-content: space-between;
  }

  .title {
    width: 174px;
    font-size: 16px;
    text-align: initial;
  }
  .doctor {
    top: -170px;
    right: -110px;
    width: 300px;
    
  }
}