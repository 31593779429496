@import "../../styles/variables.scss";

.descriptionContainer {
  display: flex;
  flex-direction: column;
  color: $loginInputsColor;
  margin: 0 auto;
  gap: 1%;
  height: 30%;
  margin-bottom: 40px;
}

.formInfo {
  display: grid;
  grid-template-columns: 91% 8%;
  margin-bottom: 10px;
  gap: 1%;
}

.contentOptions {
  display: grid;
  grid-template-columns:  80% 20%;
  margin-left: 16px;
  gap:16px
}

.contentOptionScore {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  gap:16px
}

.containerDescription {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}


// .scoreContainer {
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
// }

// .scoreDetail {
//   display: flex;
//   align-items: center;
// }

.scoreTitle {
  font-size: 21px;
  font-weight: initial;
  margin-bottom: 0;
  color: $primaryColor;
  text-align: end;
  top: 70px;
  right: 0;
  grid-row: 1/3;
  grid-column: 2/3;
}

.scaleName {
  font-weight: initial;
  font-size: 21px;
  color: $primaryColor
}

.lineName {
  font-size: 16px;
  color: $textColorLightBlue
}


.descriptionText {
  width: 95%;
  font-family: Gilroy-Regular;
}

.containerTime {
  @extend .containerDescription;
  margin-bottom: 0;
  grid-row: 1/3;
  grid-column: 2/3;
}

.timeText {
  @extend .descriptionText;
}



@media (max-width: 1024px) {
  .descriptionContainer {
    width: 95%;
  }
  // .responsibleLabelContainer {
  //   height: 34px;
  //   width: 170px;
  //   top: -25px;
  //   right: -2.5%;
  //   padding-top: 9px;
  //   padding-left: 17px;
  // }
  // .responsibleLabel {
  //   font-size: 13px;
  // }
  .scaleName {
    font-size: 20px;
  }
  // .lineName {
  //   font-size: 14px;
  // }
  .scoreTitle {
    font-size: 17px;
   
  }
}

// @media (max-width: 600px) {
//   .optionDiligence {
//     flex-direction: column;
//     align-items: flex-start;
//   }

// }

