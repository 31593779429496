@import "../../styles/variables.scss";

.alertContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  gap: 40px;
}

.alertTitle {
  display: flex;
  gap: 40px;
  align-items: center;
  position: relative;
  width: 100%;
  text-align: center;
  img{
    position: absolute;
    left: 40px;
  }
  h2 {
    font-family: 'Gilroy-Bold';
    font-size: 4vh;
    color: $loginInputsColor;
    width: 100%;
  }
}

.alertSubtitle {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  width: 100%;
  text-align: center;
  flex-direction: column;
  h2 {
    font-family: 'Gilroy-Bold';
    font-size: 4vh;
    color: $loginInputsColor;
    width: 100%;
  }
}
.alertDescription {
  width: 403px;
  p{
    max-width:200px;
    width: 100%;
    margin: 0 auto;
    text-align:center;
    font-family: 'Gilroy-Regular';
    line-height: 19px;
    color: $loginInputsColor;
  }
}

.alertButtons {
  display: flex;
  gap: 15px;
}

.confirmButton,
.cancelButton {
 padding: 0;
 height: 40px
}