@import '../../styles/variables.scss';

.questionsOption {
  display: grid;
  grid-template-columns: 50% 30% 16%;
  gap: 2%
}

.dataContainer {
  display: flex;
  flex-direction: column;
  gap:11px ;
  width: 100%;
  h6 {
    color: $textColorLightBlue;
    font-size: 16px ;
    font-family: 'Gilroy-Bold';
  }
  p {
    color: $loginInputsColor;
    font-size: 16px ;
    font-family: 'Gilroy-Regular';
  }
}

.buttonOptions {
  width: 100%;
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  justify-content: flex-end;
}


.buttonIcon{
  max-height: 14px;
  cursor: pointer;
}
.optionContainer{
  display: flex;
  align-items: center;
}


@media (max-width: 1024px) {
  .dataContainer {
    h6 {
      font-size: 12px ;
    }
    p {
      font-size: 12px ;
    }
  }
}

