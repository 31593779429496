@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2%;
}

.icon {
  color: $primaryColor;
  width: 4vh !important;
  height: 4vh !important;
}

.title {
  font-size: 4vh;
  color: $loginInputsColor;
  margin: 0px 7px;
  line-height: 0;
  font-family: 'Gilroy-Bold';
  font-weight: initial;
}
