@import '../../styles/variables.scss';

.parent {
  position: relative;
}

.container {
  background-color: $textColorWhite;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  padding-right: 30px;
  border: 1px solid $textColorLightBlue;
  border-radius: 11px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  &.selected {
    border: 2px solid;
    border-color: $textColorLightBlue;
  }
}

.inputField {
  // flex-grow: 1;
  min-width: 100%;
  display: none;
  border: none;
  padding: 3px;
  &:focus {
    outline: none;
  }
  &.visible {
    display: block;
  }
}

.arrowIcon {
  position:absolute;
  right: 10px;
  cursor: pointer;
}

.pill {
  align-self: center;
  padding: 5px;
  background-color: $lightGrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  border-radius: 4px;
  span {
    font-family: Gilroy-Regular;
    font-size: 12px;
    display: block;
    line-height: 1.1;
    color: $loginInputsColor;
  
    &.times {
      cursor: pointer;
    }
  }
}

.expanded {
  display: none;
  position: absolute;
  top: 100%;
  background-color: $textColorWhite;
  // border: 2px solid $otpInputsColor;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  width: 100%;
  margin-top: 3px;
  transition: all ease-in-out 0.5s;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  &.visible {
    display: block;
  }
}

.option {
  color: $loginInputsColor;;
  font-family: Gilroy-Regular;
  font-size: 12px;
  padding: 5px 10px;
}

.option:hover {
  background-color:rgba(0, 0, 0, 0.04);
}
.noinfo {
  color: #cecece;
  font-size: 14px;
}
