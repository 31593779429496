@import "../../styles/variables.scss";

.userContainer {
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon {
 width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e2e5f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy-Bold";
  color: $textColorWhite;
  font-size: 16px;
}

.iconArrow{
  cursor: pointer;
}

.modalUser {
  position: absolute;
  right: 20px;
  width: 106px;
  height: 72px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 5px;
  z-index: 100000000;
}

@media (max-width: 1024px) {
  .icon {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
}
