@import "../../styles/variables.scss";

.logo {
  background-color: $primaryColor;
  width: 20%;
  display: flex;
  align-items: center;
  padding-left: 54px;
  box-sizing: border-box;
}

.content {
  position: relative;
  width: calc(100vw - 20%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  color: $headerLabelColor;
  font-family: Gilroy-Regular;
  font-size: 21px;
  padding: 0px 25px 0px 18px;
  margin-bottom: 18px;

}

.banner {
  width: 100%;
  height: 1vh;
}
// @media (min-width: 1367px) {
//   .logo{
//     width: 353px;
//     padding-left: 54px;
//   }
//   .content{
//     width: calc(100vw - 353px);
//   }


@media (max-width: 1024px) {

  .logo{
    width: 222px;
    padding-left: 35px;
  }
  .content{
    width: calc(100vw - 222px);
  }
  .mainTitle {
    font-size: 14px;
  }
  
}