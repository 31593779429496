@import "../../styles/variables.scss";

$radio-input-indicator-img-bg--unchecked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%237c7c7c'%3E%3Cpath d='M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Z' fill='%237c7c7c'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
$checkbox-input-indicator-img-bg--checked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%23ffffff'%3E%3Cpolygon points='9 21 1 13 4 10 9 15 21 3 24 6 9 21' fill='%23ffffff'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");

.actionListContainer {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}
.checkboxOption {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 23px;
  color: #c4c4c4;
  [type="checkbox"] {
    appearance: none;
    display: block !important;
    transition: all 200ms;
    width: unset;
    width: 15px;
    height: 15px;
    max-width: 15px;
    max-height: 15px;
    cursor: pointer;
    user-select: none;
    *,
    *::before,
    **:after,
    &::before,
    &::after {
      transition: all 200ms ease !important;
    }
    transition: all 200ms ease !important;

    &:hover {
      border-color: $buttonColor !important;
    }
    &:focus {
      border-color: $buttonColor !important;
      outline: solid rgba($buttonColor, 0.25) 4px !important;
      outline-offset: -2px !important;
    }
    &:active {
      border-color: darken(darkgray, 15%) !important;
    }
    & ~ label {
      transition: all 200ms;
      user-select: none;
      cursor: pointer;
      width: unset !important;
      vertical-align: middle;
      width: auto !important;
      line-height: 2;
      transition: all 200ms ease !important;
    }
  }

  [type="checkbox"] {
    border-radius: 4px;
    background-color: white !important;
    appearance: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    width: 18px !important;
    height: 18px !important;
    min-height: 18px !important;
    min-width: 18px !important;
    visibility: visible !important;
    opacity: 1;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    display: block !important;
    background-image: none;
    border: 2px solid $otpInputsColor !important;
    & ~ label {
      margin-left: 0.25em !important;
      display: block;
      font-weight: 500;
      width: 80% !important;
      margin-top: -0.25rem !important;
      margin-left: 1.5em !important;
      margin-bottom: 0.5em !important;
    }

    &:checked {
      background-image: $checkbox-input-indicator-img-bg--checked;
      background-position: 0 0;
      background-size: 14px;
      background-color: $buttonColor !important;
      border-color: $buttonColor !important;
    }
  }
}
