@import "../../styles/variables.scss";

.container {
  padding: 22px 24px;
  box-sizing: border-box;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content:center;
  gap: 5px;
}

.patientName {
  color: $primaryColor;
  font-size: 20px;
  text-align: center;
  font-family: Gilroy-Bold;
  font-weight: initial;
}

.data {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  :nth-child(1) {
    grid-column: 1/4;
  }

  :nth-child(2) {
    grid-column: 4/7;
  }

  :nth-child(4) {
    grid-column: 2/4;
  }
  :nth-child(5) {
    grid-column: 4/7;
  }
}

.dataTitle {
  font-size: 12px;
  color: $textColorLightBlue;
  font-family: Gilroy-Bold;
  padding-left: 0;
}

.dataInfo {
  font-size: 12px;
  color: $loginInputsColor;
  font-family: Gilroy-Regular;
}

.buttonContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
