@import "../../styles/variables.scss";

.cardContainer {
  padding-left: 20px;
}

.selectOption {
  margin-top: 15px;
  width: 66.6%;
}

.titleCard {
  font-family: Gilroy-Bold;
  color: $primaryColor;
  font-size: 18px;
  line-height: 24px;
}