@import "../../styles/variables.scss";

.modalContainer {
  padding: 25px;
  height: auto;
  cursor: default;
}

.successHeader,
.errorHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 80px;
}

.titleMessage {
  font-size: 30px;
  color: $primaryColor;
  line-height: 38px;
  font-family: Gilroy-Bold;
}

.sentSuccesfully,
.sentError {
  color: $loginInputsColor;
  font-size: 14px;
  line-height: 17px;
  font-family: Gilroy-Regular;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.successContainer {
margin-bottom: 10px;
}

.errorContainer {
  margin-bottom: 10px;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttonRedirect {
  width: 131px;
  height: 33px;
  background: $buttonColor;
  border-radius: 12px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  font-family: Gilroy-Regular;
  margin: 0 auto;
}