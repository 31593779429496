@import "../../styles/variables.scss";

.options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 12vh;
  right: 2%;
  width: 100%;
  z-index: 1;
}
.dateLabel {
  color: $buttonColor;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.3;
}

.headerLabel {
  width: 100%;
  height: 100%;
}

.headerSorted {
  width: 0;
  display: inline-block;
}

.pagination {
  margin-top: 2vh;
  bottom: 0px;
  position: relative;
}

.icon {
  width: 2vh !important;
  height: 2vh !important;
  margin-right: 4px !important;
}

.arrowSort {
  // position: absolute;
  top: 7px;
  margin-left: 0.5rem;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .dataLabelContainer {
    width: 5rem;
  }
  .dateLabel {
    font-size: 10px;
  }
  .arrowSort {
    top: 3px;
  }
}

@media (min-width: 1367px) {
  .dateLabel {
    font-size: 14px;
  }
  .arrowSort {
    top: 50%;
    width: 0.6rem;
  }
}

@media (min-width: 2000px) {
  .arrowSort {
    top: 50%;
    width: 0.8rem;
  }
}
