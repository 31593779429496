@import "../../styles/variables.scss";

.questionGroup{
  display: flex;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.normalQuestion{
  width: 18px;
  height: 18px;
  border: 1.5px solid $otpInputsColor;
  border-radius: 50%;
  background-color: $textColorWhite;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer

}

.completeQuestion{
  background-color: #69CA63;
  border-color: #69CA63;
}

.requiredQuestion{
  background-color: $textColorWhite;
  border-color: $errorColor;
}