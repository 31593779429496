@import "../../styles/variables.scss";

.navbar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-right-radius: 11px;
  background-color: $primaryColor;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.btnDropdownParent {
  padding: 15px;
  z-index: 2;
}

.btnClosed {
  transform: rotateY(180deg);
}

.btnDropdown {
  float: right;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menuIcon {
  width: 2vh;
  height: 2vh;
}

.navbarTitle{
  margin-bottom: 36px;
}

.titleDropped{
  display: none;
}

.title{
  display: block;
  font-size: 25px;
  font-family: "Gilroy-Bold";
  text-align: center;
  cursor: pointer;
}

.versionTitle {
  font-size: 10px;
  font-family: "Gilroy";
  text-align: center;
}

.linksZone {
  flex-grow: 1;
  overflow: auto;
}

.linksZone::-webkit-scrollbar{
  appearance: none;
}

.linksZone::-webkit-scrollbar:vertical{
  width: 10px;
}
.linksZone::-webkit-scrollbar-button:increment, .linksZone::-webkit-scrollbar-button{
  display: none;
}

.linksZone::-webkit-scrollbar:horizontal{
  height: 10px;
}

.linksZone::-webkit-scrollbar-thumb {
  background-color: $otpInputsColor;
  border-radius: 20px;
  border: 2px solid $otpInputsColor;
  
}

.linksZoneDropdown {
  padding-left: 10%;
}

.footerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}


@media (min-width: 1367px) {
  .title{
    font-size: 30px;
  }
  .versionTitle{
    font-size: 15px;
    margin-bottom: 49px;
  }
}
@media (max-width: 1024px) {
  .title{
    font-size: 20px;
  }
  .versionTitle{
    font-size: 8px;
    margin-bottom: 35px;
  }
}