@import "../../styles/variables.scss";

.listContainer {
  display: grid;
  grid-template-columns: 97% 2%;
  gap: 1%;
  position: relative;
}

.titleComment {
  font-family: Gilroy-Bold;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.activeCommentTitle {
  @extend .titleComment;
  color: $buttonColor;
}

.inactiveCommentTitle {
  @extend .titleComment;
  color: $grey;
}

.comment {
  margin-top: 14px;
  font-family: Gilroy-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.title {
  font-family: "Gilroy-Medium";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.activeTitle {
  @extend .title;
  color: $primaryColor;
}

.inactiveTitle {
  @extend .title;
  color: $grey;
}

.moreButton {
  background-color: transparent;
  border: none;
  padding: none;
  cursor: pointer;
}
.commentContainer {
  background-color: $greyAccordion;
  border-radius: 12px;
  box-sizing: border-box;
  color: $loginInputsColor;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-height: 20px;
  padding: 10px 13px;
  width: 100%;
  gap: 5px;
}

.textArea {
  border: none;
  background-color: transparent;
  min-height: 20px;
  width: 100%;
  font-family: "Gilroy-Medium";
  resize: none;
  color: $loginInputsColor;
  overflow: hidden;
}

.textArea:focus-visible {
  outline: none;
}

.sendButton {
  align-self: end;
  align-items: center;
  background-color: transparent;
  border: none;
  color: $buttonColor;
  cursor: pointer;
  display: flex;
  font-family: Gilroy-Bold;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  gap: 2px;
  line-height: 16px;
}

.optionsContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 97px;
  height: 72px;
  border-radius: 11.424px;
  background: $textColorWhite;
  box-shadow: 0px 0px 7.85404px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 17px 0;
  box-sizing: border-box;
  justify-content: space-around;
}

.optionButton{
  background-color: transparent;
  border: none;
  color: $loginInputsColor;
  font-family: Gilroy-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 0;
  text-align: initial;
  cursor: pointer;
  padding: 0 19px;
  width: 100%;
}

.optionButton:hover {
  background-color: $lightGrey;
}