@import "../../styles/variables.scss";

.container {
  align-items: center;
  border-radius: 20px;
  background-color: $textColorWhite;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 11px 0px rgba(120, 135, 160, 0.25);
  display: flex;
  flex-direction: column;
  height: 266px;
  justify-content: space-evenly;
  margin: auto;
  padding: 36px auto;
  position: relative;
  width: 458px;
}
.logo {
  width: 123px;
}

.title {
  color: $primaryColor;
  text-align: center;
  font-family: Gilroy-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.titleBold {
  color: $primaryColor;
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 7px;

}

.form {
  width: 179px;
}

.woman{
  width: 129px;
  height: 386px;
  position: absolute;
  right: -60px;
  top: -30px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  text-align: left;

  div {
    margin-top: 0.4vh;
    span {
      color: $errorColor;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.8vh;

      :first-child {
        margin-right: 1vh;
        width: 2vh;
      }
    }
  }
}

.textPass {
  font-family: Gilroy-Regular;
  font-size: 1.4vh;
  color: $loginInputsColor;
  text-align: center;
  margin-top: 2vh;
}
