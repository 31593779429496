@import "../../styles/variables.scss";

.loginContainer {
  background-image: url("../../assets/imgs/LogoBackground.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.copy {
  color: $primaryColor;
  font-family: Gilroy-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


@media (max-width: 600px) {
  .loginContainer {
    justify-content: space-around;
    background-image: url("../../assets/imgs/background_mobile.png");
  }
  .copy{
    width: 188px;
    font-size: 11px;
  }
}