@import "../../styles/variables.scss";

.toggleSwitch {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.toggleSwitchCheckbox {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.toggleSwitchSwitch {
  display: flex;
  cursor: pointer;
  width: 25.79px;
  height: 12.9px;
  border: 1.07477px solid;
  border-radius: 17.2px;
  border-color: $buttonColor;
  background-color: white;
  position: relative;
  transition: background-color 0.2s;
  align-items: center;
  div {
    padding-left: 30px;
  }
}

.toggleSwitchSwitch::before {
  content: ' ';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 11.4px;
  height: 11.4px;
  border-radius: 50%;
  transition: 0.2s;
  background-color: $buttonColor;
  box-shadow: 0px 3px 10px #00000029;
}

.toggleSwitchSwitch::after {
  display: block;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 18px;
  transition: all 0.4s;
}

.checked {
  background-color:#C6E9EC;
}

.checked::before {
  left: 14px;
}
.checked::after {
  padding-left: 4px;
  content: ' ';
  color: black;
}