@import "../../styles/variables.scss";

.container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: $logoFsfbBackground;
    color: $loginInputsColor;
    background-repeat: no-repeat;
    background-position: right;
    padding-top: 20vh;
    width: auto;
    height: 100%;
    background-size: contain;
}

.welcomeImageContainer{
    position: absolute;
    display: flex;
    border-bottom-left-radius: 10vh;
    border-bottom-right-radius: 10vh;
    background-color: rgb(181, 220, 104);
    padding-top: 9vh;
    top: 0;
}

.welcomeImage{
    display: flex;
    background-color: $otpInputsColor;
    border-radius: 50%;
    width: 12vh;
    height: 12vh;
}


.containerButtons{
    display: flex;
    width: 46vh;
	justify-content: space-between;
    margin-top: 2vh;
}

.message{
    text-align: center;
}

.labelDescription {
    font-family: Gilroy-Regular;
}
.ScaleDescription {
    font-family: Gilroy-Regular;
}

.updateTitleCustom {
    color: $loginInputsColor;
}

.btnsContainerCustom {
    flex-direction: row-reverse;
}

.cancelButtonCustom {
    color: $textColorWhite;
    background-color: $buttonColor;
}

.nextButtonCustom {
    color: $textColorLightBlue;
    background-color: $textColorWhite;
    border: 1px solid;
    border-color: $textColorLightBlue;
}

@media (min-width: 601px) {
    .container {
        justify-content: flex-end;
    }
    .welcomeImageContainer {
        border-bottom-left-radius: 12vh;
        border-bottom-right-radius: 12vh;
        padding-top: 12vh;
    }
    .welcomeImage {
        width: 23vh;
        height: 23vh;
    }
    .message {
        margin-top: 13vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .labelDescription {
        font-size: 2.5vh;
    }
    .ScaleDescription {
        font-size: 2vh;
        width: 50%;
    }
    .approximateTime {
        height: 9.75vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-family: 'Gilroy-Medium';
            font-size: 2vh;
            color: $primaryColor;
        }
    }
    .containerButtons {
        margin-bottom: 5%;
    }
}

@media (max-width: 600px) {
    .container {
        padding-top: 0;
        justify-content: normal;
        height: 85%;
        position: relative;
    }
    .welcomeImageContainer {
        position: initial;
        padding-top: 3vh;
    }
    .welcomeImage {
        width: 20vh;
        height: 20vh;
    }
    .message {
        width: 80%;
        margin: auto;
    }
    .approximateTime {
        height: 9.75vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            font-family: 'Gilroy-Medium';
            font-size: 2vh;
            color: $primaryColor;
        }
    }
    .containerButtons {
        margin-bottom: 10%;
        width: 42vh;
    }
}
