@import "../../styles/variables.scss";

.tableContainer {
  margin: 30px 0px;
}

.tableStyle {
  width: 648px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 12px;
  font-size: 14px;
  h6 {
    color: $textColorLightBlue;
    font-family: "Gilroy-Bold";
    font-size: 14px;
  }
  p {
    color: $loginInputsColor;
    font-family: "Gilroy-Regular";
  }
}

@media (max-width: 1024px) {
  .tableStyle {
    width: 480px;
    font-size: 12px;
    h6{
      font-size: 12px;
    }
  }
}
