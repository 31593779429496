@import "../../styles/variables.scss";

.cancelButtonCustom {
  background-color: $buttonColor;
  color: $textColorWhite;
}

.nextButtonCustom {
  color: $textColorLightBlue;
  background-color: $textColorWhite;
  border: 1px solid;
}