@import '../../styles/variables.scss';

.container {
  height: 100%;
  background-color: $textColorLightBlue;
  background-image: $logoFsfbBackground;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%
}

.subContainer {
  display: flex;
  width: 100%;
  height: 80%;
  justify-content:flex-start;
}

.titleSection {
  width:30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;
  align-items: flex-start;
}

.title {
  font-size: 36px;
  font-family: Gilroy-Bold;
  line-height: 36px;
  max-width: 100%;
  height: 80%;
  text-align: left;
  color: $textColorWhite;
  // overflow: scroll;
}

.docImgContainer {
  display: flex;
  justify-content: center;
  height: 100%;
}

.docImg {
  height: 100%;
  width: 100%;
}

.btnContainer {
  height: 20%;
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .container {
    background-image: none;
    justify-content: flex-start;
    flex-direction: column;
  }
  .subContainer {
    flex-direction: column;
    height: 85%;
    z-index: 2;
  }
  .titleSection {
    width: 100%;
    align-items: center;
    height: 70%;
    justify-content: flex-start;
  }
  .title {
    text-align: center;
    font-size: 36px;
    line-height: 36px;
    padding-top: 20px;
    max-height: fit-content;
    height: 60%;
  }
  .logoFsfb {
    position: absolute;
    top: 25.25vh;
    right: -25.87vh;
    width: 44.5vh;
    z-index: 1;
  }
  .docImgContainer {
    height: 30%;
    min-height: 200px;
  }
  .docImg {
    height: 200px;
    width: 200px;
  }
}