@import "../../styles/variables.scss";

.container {
    height: auto;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userInfoDetail {
    display: grid;
    grid-template-columns: 80% 20%;
    padding: 15px 0 20px;

    >div {
        align-self: center;
        justify-self: end;
        font-size: 10px;
        line-height: 16px;
        color: $grey;
        gap: 4px;
        padding: 0;

        img {
            width: 10px;
            height: 10px;
        }
    }
}

.userInfoContainer {
    display: grid;
    grid-template-columns: 25% 25% 50%;
    gap: 2px 12px;
}

.userInfoId {
    display: flex;
    align-items: center;
    gap: 8px;
}

.name {
    color: $primaryColor;
    font-size: 16px;
    line-height: 24px;
    font-family: Gilroy-Bold;
}

.user_doc {
    color: $buttonColor;
    font-size: 14px;
    line-height: 17px;
    font-family: Gilroy-Regular;
}

.detail {
    align-items: center;
    display: flex;
    gap: 4px;
    font-size: 14px;
    font-family: "Gilroy-Light";

    .title {
        font-family: Gilroy-Bold;
        line-height: 16px;
    }

    .value {
        font-family: Gilroy-Regular;
        line-height: 14px;
    }

    p {
        color: $loginInputsColor;
        font-size: 12px;
        word-break: break-word;
    }
}

.updateData {
    justify-self: end;
}