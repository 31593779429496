@import "../../styles/variables.scss";

.questionsContainerOption{
  margin-top: 60px;
  display: flex;
  align-items: center;

}

.listOptionsButtons {
  display: flex;
  margin-top: 20px;  
  gap: 9px;
}


.sectionButton {
  background-color: $primaryColor ;
  color: white ;
  border: 1px solid ;
  border-radius: 11px ;
  border-color: transparent ;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12);
  height: 34px ;
  // margin-right: -15px;
  // padding-right: 30px;
  opacity: 0;
  transition: opacity 0.5s ease;

}

.sectionButton.visible{
  opacity: 1;
}


.optionButton {
  border: 1px solid ;
  border-radius: 11px ;
  border-color: transparent ;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12) ;
  height: 34px ;
  // margin-right: -15px;
  // padding-right: 30px;
  background-color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.optionButton.visible{
  opacity: 1;
}


.addButton {
  font-family: 'Gilroy-Bold';
  border: 1px solid ;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12) ;
  height: 34px ;
  gap: 5px;
  padding: 0 10px;
  background-color: white;
  // margin-right: -15px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: $buttonColor;
  font-size: 14px;
  cursor: pointer;
  border-radius: 11px;
  border: 0.704px solid var( #009DAC);
}
.addImage{
  width: 24px;
}

.withoutText {
  // padding-right: 20px;
}


.initialButton{
  opacity: 0;
  transition: opacity 0.5s ease;
}
.visible{
  opacity: 1;
  display: flex;
}

