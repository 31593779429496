@import "../../styles/variables.scss";

.chartContainer {
  width: 100%;
  height: 210px;
  border-radius: 8px;
  background: var(--Text-Alternative, #fff);
  box-shadow: 2px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 12px;
  margin-top: 12px;

  canvas {
    padding: 8px 0 26px;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.titleChart {
  color: $mainBlue;
  font-family: Gilroy-Bold;
  font-size: 14px;
  line-height: 16px;
}

.total {
  background: $greyAccordion;
  border-radius: 5px;
  color: $mainBlue;
  font-family: Gilroy-Regular;
  font-size: 10px;
  line-height: 16px;
  padding: 5px 10px;
}

.filters {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;

  >div section {
    width: 100%;
  }

  >div:first-child {
    width: 15%;
  }

  >div:nth-child(2) {
    width: 20%;
  }
}