@import "../../styles/variables.scss";

.managerScalecontainer {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $textColorWhite;
  margin-bottom: 25px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-left: none;
  border-top: none;
  border-bottom-right-radius: 11px;
  padding-bottom: 12.5px;
  color: $loginInputsColor;
}

.managerScalecontainer:nth-child(1) {
  padding-top: 28.5px;
}

.titleRemark {
  color: $buttonColor;
  cursor: pointer;
  font-family: "Gilroy-Regular";
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: underline;
  padding-right: 12px;
  text-align: end;
}

.containerRemark{
  padding-right: 12px;
}

.optionRadioContainer {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
}

.gridAuto {
  display: grid !important;
  grid-template-columns: auto;
}

.optionsContainer {
  display: flex !important;
  flex-direction: column;
  gap: 7px;
}

.selectContainer {
  width: 50%;
}

.labelStyle {
  color: $loginInputsColor;
  font-size: 14px;
}

.withSection {
  margin-left: 22px;
  margin-right: 22px;

}

@media (max-width: 1024px) {
  .managerScalecontainer {
    margin-bottom: 20px;
  }
  .managerScalecontainer:nth-child(1) {
    padding-top: 20px;
  }
  .labelStyle {
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  .optionsContainer {
    width: 100%;
  }
}
