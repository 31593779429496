@import "../../styles/variables.scss";

.selectContainer {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  gap: 3px;
}

.buttonOption {
  color: $grey;
  font-family: Gilroy-Bold;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 8px;
  border: 0.75px solid var(--gris-componentes, #DEDEDE);
  width: 75px;
  height: 21px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}

.dropdownContainer {
  position: absolute;
  top: 32px;
  color: black;
  width: max-content;
  right: 0;
  border-radius: 8px;
  background: $textColorWhite;
  box-shadow: 1px 0px 6.309999942779541px 0px rgba(0, 0, 0, 0.20);
  z-index: 1;
}

.filtersOptions {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.clearFilter {
  color: $textColorLightBlue;
  font-family: Gilroy-Regular;
  font-size: 12px;
  font-style: normal;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  padding: 10px 18px;
}