@import "../../styles/variables.scss";

.loginButton {
  background-color: $buttonColor;
  font-family: Gilroy-Bold;
  font-size: 16px;
  color: $textColorWhite;
  border: 1px solid;
  border-color: $buttonColor;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 40px;
  border-radius: 10px;
}

.loginPatient {
  @extend .loginButton;
  width: 100%;
}

.disabledButton {
  background: $otpInputsColor;
  border-color: $otpInputsColor;
}

.todayStyles {
  border-radius: 16px;
  padding: 1.5vh 0;
  margin-top: 0;
  margin-bottom: 0;
}

.summaryStyles {
  width: 23vh;
  max-width: 180px;
  border-radius: 16px;
  margin: auto;
  font-family: Gilroy-Regular;
}

