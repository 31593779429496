@import "../../styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  left: 2vh;
  position: absolute;
  top: 2vh;
}

.mainTitle {
  color: $textColorWhite;
  font-size: 1.8vh;
  margin-left: 1.2vh;

  // fix firefox multiprocess
  font-weight: initial;
}

@media (max-width: 600px) {
  .container {
    position: initial;
    background-color: $primaryColor;
    height: 15%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    justify-content: center;
    padding-top: 2vh;
    padding-right: 3vh;
    width: 100%;
  }
}
