@import "../../styles/variables.scss";

.banner {
  width: 100%;
  display: flex;
  height: 12px;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.bannerColor_2 {
  flex-grow: 1;
  background-color: $loginLabelColor;
}

.bannerColor_3 {
  flex-grow: 1;
  background-color: $iconsBlueColor;
}

.bannerColor_4 {
  flex-grow: 1;
  background-color: $green;
}

.bannerColor_5 {
  flex-grow: 1;
  background-color: $orange;
}
