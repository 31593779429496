@import "../../styles/variables.scss";

.cardContainer{
  position: relative;
}

.iconAccordion{
  position: absolute;
  right: 0;
  top:0;
  cursor: pointer;
}

.iconAccordionPatient {
  position: absolute;
  right: 21px;
  top: 22px;
  cursor: pointer;
}

.rotateIcon {
  transform:  rotate(180deg);
}

.titleCard {
  color: $primaryColor;
  font-size: 18px;
  line-height: 24px;
}












