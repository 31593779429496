@import "../../styles/variables.scss";

.confirmContainer {
  display: flex;
  flex-direction:column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 40px 0px;
}

.title {
  font-family: 'Gilroy-Bold';
  font-size: 32px;
  text-align: center;
  color: $primaryColor;
  font-weight: initial;
  white-space: pre-line;
}

@media (max-width: 1024px) {
  .title {
    font-size: 30px;
  }
}