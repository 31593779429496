@import "../../styles/variables.scss";

.thermoContainer {
    color: $loginInputsColor;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.thermoLabel {
    font-family: Gilroy-Bold;
    font-size: 14px;
    font-weight: initial;
    width: 130px;
}

.thermoLabelPatient {
    font-family: Gilroy-Bold;
    font-size: 14px;
    font-weight: initial;
    width: 130px;
}

.thermoInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .thermoTxt {
        font-family: Gilroy-Regular;
        font-size: 10px;
    }
}

.termometro {
    width: 87px;
    height: 300px;
    background: url(../../assets/imgs/thermometer.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
}

.termometroPat {
    width: 87px;
    height: 175px;
    background: url(../../assets/imgs/thermometer.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
}

.slider {
    -webkit-appearance: none;
    width: 275px;
    background-color: transparent;
    position: absolute;
    transform: rotate(-90deg);
    left: -94px;
    bottom: 140.5px;
}

.sliderPat {
    -webkit-appearance: none;
    width: 167px;
    background-color: transparent;
    position: absolute;
    transform: rotate(-90deg);
    left: -40px;
    bottom: 78px;
}

.slider[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 4px;
    width: 4px;
    background: rgba(120, 133, 145, 1);
    border-color: transparent;
}

.slider[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: rgba(120, 133, 145, 1);
    border-color: transparent;
}

.slider[type="range"]::-webkit-slider-runnable-track  {
    margin-bottom: 6.5px;
    padding-top: 6.5px;
  }

.sliderPat[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 4px;
    width: 4px;
    background: rgba(120, 133, 145, 1);
    border-color: transparent;
}

.sliderPat[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: rgba(120, 133, 145, 1);
    border-color: transparent;
}

.sliderPat[type="range"]::-webkit-slider-runnable-track  {
    margin-bottom: 6.5px;
    padding-top: 6.5px;
}


@media (min-width: 1000px) {
    .termometroPat {
        height: 225px;
    }

    .sliderPat {
        width: 212px;
        left: -62px;
        bottom: 103px;
    }
}

@media (min-width: 601px) and (max-width: 960px) {
    .termometroPat {
        height: 125px;
    }

    .thermoLabelPatient {
        font-size: 12px;
    }

    .sliderPat {
        width: 117px;
        left: -15px;
        bottom: 53px;
    }

    .sliderPat[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 2px;
        width: 2px;
        background: rgba(120, 133, 145, 1);
        border-color: transparent;
    }
    
    .sliderPat[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background: rgba(120, 133, 145, 1);
        border-color: transparent;
    }
    
    .sliderPat[type="range"]::-webkit-slider-runnable-track  {
        margin-bottom: 6.5px;
        padding-top: 6.5px;
    }
}
