@import "../../styles/variables.scss";

.form {
  width: 90%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 23px;
}

.formContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.datePickers {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 35px;
}

.labelRange {
  width: 140px;
  position: relative;

  h3 {
    font-size: 16px;
    line-height: 26px;
    color: $loginInputsColor;
    font-weight: initial;
  }
}

.label {
  font-size: 16px;
  line-height: 26px;
  color: $loginInputsColor;
  font-weight: initial;
}

.inputLineContainer {
  width: 100%;
  position: relative;
}

.inputScaleContainer {
  width: 100%;
  position: relative;
}



.selectOption {
  select {
    border: 1px solid rgba(210, 217, 233, 1);
    padding: 1.2vh;
    border-radius: 10px;
    padding-right: 10px;
  }
}
.noData {
  color: $errorColor;
  padding: 10px 0;
  text-align: center;
}

.loginInputs {
  font-family: Gilroy-Regular;
  color: $loginInputsColor;
  border: 1px solid;
  border-radius: 11px;
  border-color: $otpInputsColor;
  padding: 5px;
  outline-color: $textColorLightBlue;
  width: 100%;
  font-size: 16px;

  // fix firefox multiprocess
  background-color: white;

  background-image: url(../../assets/imgs/icons/arrowSelect.svg);
  background-repeat: no-repeat;
  background-position: 98% center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.documentOptions {
  font-family: Gilroy-Regular;
}

.errorMessage {
  color: $errorColor;
  display: flex;
  align-items: center;
  font-size: 14px;
  position: absolute;
  padding-top: 0.5rem;
}

.exclamationIcon {
  margin-right: 0.8vh;
  width: 1.2vh;
}

.boxIcon {
  width: 2vh !important;
  margin-right: 1vh !important;
}

@media (max-width: 960px) {
  .loginInputs {
    font-size: 11px;
  }
}
