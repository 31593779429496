@import "../../styles/variables.scss";

.network {
  display: flex;
  align-items: center;
  font-family: Gilroy-Bold;
  font-size: 13px;
  font-weight: inherit;
  color: $textColorWhite;
  width: 100%;
  gap: 6px;
  justify-content: end;
  align-items: center;
}


@media (max-width: 600px) {
  .network {
    justify-content: center;
  }
}