@import "../../styles/variables.scss";

.optionsContainer{
  gap: 10px;
}
.radioContainer{
  width: 273px;
  background-color: $textColorWhite;
  height: 47px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  color: $loginTitleColor;
  display: grid !important;
  grid-template-columns: 15% 85%;
  grid-auto-flow: dense;
  direction: rtl;
  text-align: center;
  margin: 0 !important;
}


@media (min-width: 601px) {
  .radioContainer {
    height: auto;
  }
}
