@import "../../styles/variables.scss";

.formContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loading {
  display: none
}

.updateTitle {
  font-family: 'Gilroy-Bold';
  font-weight: initial;
  font-size: 35px;
  line-height: 28px;
  text-align: center;
  color: $primaryColor;
  padding-top: 12px;
}

.patientName{
  font-family: 'Gilroy-Regular';
  font-weight: initial;
  font-size: 16px;
  line-height: 21px;
  color: $loginTitleColor;
}

.inputAndError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 0px 20px;
}

.loginInputs {
  font-family: Gilroy-Regular;
  font-size: 16px;
  color: $loginInputsColor;
  border: 2px solid;
  border-color: rgba(210, 217, 233, 1);
  padding: 16px 56px 16px 16px;
  outline-color: $textColorLightBlue;
  width: 100%;
  border-radius: 16px;
  align-self: center;

  // fix firefox multiprocess
  background-color: white;
}

// select icon
.loginInputs {
  background-image: url(../../assets/imgs/icons/arrowSelect_blue.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 95%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.loginInputs::-ms-expand {
  display: none;
}

.errorMessage {
  color: $errorColor;
  display: flex;
  align-items: center;
  font-size: 16px;
  position: absolute;
  left: 55%;
  top: 145px;
}

.inputContainer{
  display: flex;
  gap: 8px;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
}

.buttonOption {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.pointsStep {
  color: $textColorLightBlue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}
.buttonBase{
  width: 131px;
  height: 33px;
  border-radius: 12px;
  padding: 0;
  display: flex;
  justify-content:center;
  align-items: center;
}

.stepperContainer {
  height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.circleContainer {
  display: flex;
}

@media (max-width: 1024px) {
  .updateTitle {
    font-size: 30px;
  }
}