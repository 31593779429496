@import "../../styles/variables.scss";

.resumeTitle {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.resumeText {
  font-size: 12px;
  line-height: 16px;
  font-family: "Gilroy-Regular";
  color: $grey;
  cursor: default;
}

.resumeLine {
  border: 0.7px solid #5AC3F0;
  width: 100%;
}

.listCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 19px 0 0;
  gap: 24px;

  >div {
    justify-self: center;
    max-width: unset;
    width: 100%;
    margin: 0;
  }
}

.accordionUp {
  transform: rotate(180deg);
}