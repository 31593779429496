@import "./styles/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  background: #FFF;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./assets/fonts/Gilroy-Bold.ttf");
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("./assets/fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-family: "Gilroy-Light";
  src: url("./assets/fonts/Gilroy-Light.ttf");
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("./assets/fonts/Gilroy-ExtraBold.ttf");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Gilroy-Bold",
    "Gilroy-Regular", "Gilroy-Light", "Gilroy-ExtraBold", "Gilroy-Medium",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", "Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

@media (max-width: 480px) {
  body {
    overflow-x: hidden;
  }
}
