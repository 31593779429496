@import "../../styles/variables.scss";

.scalePreviewContainer {
  display: flex;
  flex-direction: column;
  margin: 17px 34px;
  color: $loginInputsColor;
  font-family: "Gilroy-light";
}

.viewScale {
  display: flex;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: $buttonColor;
  font-size: 16px;
  img {
    width: 18px;
  }
}

.imageScale {
  margin-top: 1vh;
  max-width: 400px;
  max-height: 400px;
  min-height: 200px;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.titleContainer {
  display: grid;
  grid-template-columns: 85% auto;
  gap: 10px;
}

.titleScale {
  color: $primaryColor;
  font-family: "Gilroy-Bold";
  font-size: 21px;
}

.timingOption {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-self: end;
}

.serviceLinesOpt {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  li {
    list-style: none;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    color: $textColorLightBlue;
    padding: 5px;
  }
}


.containerDescription {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-top: 16px;
}




.timingOptions {
  display: grid;
  grid-template-columns: 65% 30%;
  gap: 5%;
}

.title {
  color: $loginInputsColor;
  font-family: "Gilroy-Medium";
  font-size: 3.5vh;
}
.optionContainer {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 10px;
}

.titleOtpion {
  font-family: "Gilroy-Bold";
  font-size: 16px;
  color: $loginInputsColor;
  margin: 0;
}
.linesContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin-top: 10px;
}

.listLinesServices {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  li {
    list-style: none;
    padding: 5px;
    background-color: #f5f5f5;
    color: $loginInputsColor;
    border-radius: 4px;
  }
}

.periodicityOption {
  display: flex;
  gap: 25px;
  align-items: center;
}

.periodicityOptionList {
  font-family: "Gilroy-Light";
}

.periodicityTitle {
  font-family: "Gilroy-Medium";
}

.timeOptionList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionContainer {
  background: $textColorLightBlue !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 11px !important;
  padding: 22px 47px !important;
  margin: 10px 0 !important;
  box-sizing: border-box;
  font-family: "Gilroy-Bold";
  font-size: 2vh;
  color: $textColorWhite !important;
  display: flex;
  justify-content: space-between;
  div {
    margin: 0px !important;
  }
  svg {
    color: white;
    border: 2px solid white;
    border-radius: 50%;
  }
}

.buttonOption {
  margin-top: 30px;
  align-self: flex-end;
}

.accordion {
  box-shadow: none !important;
  margin: 0px !important;
}

.accordion::before {
  height: 0 !important;
}

.buttonSend {
  max-height: 50px;
  height: 50px;
  min-width: 220px;
  border-radius: 16px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
