@import "../../styles/variables.scss";

.modulesSelectContainer {
  display: grid;
  grid-template-columns: 9% 89%;
  gap: 25px;
  width: 100%;
  align-items: center;margin-bottom: 17px;
}

.modulesSelect{
  width: 100%;
}

@media (min-width: 1367px) {
  .modulesSelectContainer {
    grid-template-columns: 10% auto;
    gap: 25px;
    margin-bottom: 0px;
  }
  
}