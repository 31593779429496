@import "../../styles/variables.scss";

.left {
  width: 100%;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.sub1 {
  font-family: Gilroy-Bold;
  margin-bottom: 6px;
}

.sub2 {
  font-family: Gilroy-Regular;
}

@media (max-width: 600px) {
  .left {
    font-size: 10px;
    padding-bottom: 40px;
    text-align: center;
  }
  .sub2{
    display: none;
  }
}
