@import "../../styles/variables.scss";

.baseButton {
  padding: 8px;
  min-width: 127px;
  cursor: pointer;
  font-size: 12px;
}

.contained {
  border: 1px solid;
  border-radius: 10px;
}

.contained.primary {
  background-color: $buttonColor;
  border-color: $buttonColor;
}

.contained.secondary {
  background-color: $textColorLightBlue;
  border-color: $textColorLightBlue;
}

.contained,
.primary,
.secondary {
  color: $textColorWhite;
}

.text {
  border: none;
  background-color: transparent;
  gap: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  text-decoration: underline;
}

.text.primary {
  color: $buttonColor;
}

.text.secondary {
  color: $textColorLightBlue;
}

.text.grey{
  color:$loginInputsColor;
}

.outlined {
  border: 1.5px solid;
  border-radius: 10px;
}

.outlined {
  background-color: transparent;
}

.outlined.primary {
  color: $buttonColor;
  border-color: $buttonColor;
}

.outlined.secondary {
  color: $textColorLightBlue;
  border-color: $textColorLightBlue;
}

.outlined.disabled {
  background-color: transparent;
  border-color: $grey;
  color: $grey;
}

.disabled {
  background-color: $grey;
  color: $textColorWhite;
  border-color: $grey;
  cursor: initial;
}

@media (max-width: 1024px) {
  .baseButton {
    font-size: 12px;
  }
}
