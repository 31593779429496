@import "../../styles/variables.scss";

.questionContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-right: 12px;
  box-sizing: border-box;
}

.title {
  font-family: Gilroy-Bold;
  font-size: 16px;
  color: $grey;
}