@import "../../styles/variables.scss";

.listBreadcumb {
  display: flex;
  flex-direction: row;
}

.breadcumb {
  display: flex;
}

.breadcumbItem {
  list-style: none;
}

.buttonBreadCumb {
  font-family: 'Gilroy-Regular';
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: $primaryColor;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  font-family: Gilroy-Bold;
  cursor: initial;
}


.iconSeparator {
  padding: 0px 5px;
  height: 14px;
}

@media (min-width: 1367px) {
  .buttonBreadCumb {
    font-size: 21px;
  }
}

@media (max-width: 1024px) {
  .buttonBreadCumb {
    font-size: 14px;
  }
}