@import "../../styles/variables.scss";

.formContainer {
  height: 100%;
  width: 100%;
  padding: 0 28px;
}

.stepperContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.loading {
  display: none;
}

.pointsStep {
  color: $textColorLightBlue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.circleContainerBig {
  width: 12px;
  height: 12px;
  background-color: #00ccdf;
  border-radius: 50%;
}

.circleContainerSmall {
  width: 6px;
  height: 6px;
  background-color: #00ccdf;
  border-radius: 50%;
}

.buttonOption {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  justify-content: center;
  padding-bottom: 22px;
}
