@import "../../styles/variables.scss";

.dropdownList {
  list-style: none;
  color: $grey;
  font-family: Gilroy-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 11px 0;

  li {
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
  }
  li:hover {
    background-color: $greyAccordion;
  }
}

.imgContainer {
  width: 15px;
  display: flex;
  justify-content: center;
}