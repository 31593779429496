@import "../../styles/variables.scss";

.centerContainer {
  display: flex;
  justify-content: center;
}

.welcomeTitle {
  font-family: Gilroy-Bold;
  color: $loginInputsColor;
  font-size: 4.4vh;
  font-weight: initial;
  margin-bottom: 1.2vh;
}

.centerUser {
  font-family: Gilroy-Regular;
  color: $loginInputsColor;
  font-size: 4.4vh;
  font-weight: initial;
}

.title2 {
  font-family: Gilroy-Bold;
  font-size: 4.8vh;
  font-weight: initial;
}

.subtitle {
  font-family: Gilroy-Regular;
  font-size: 2em;
  font-weight: bold;
}

.txtOtp {
  font-family: Gilroy-Regular !important;
  font-size: 3.6vh !important;
  font-weight: initial !important;
}

.text1 {
  font-family: Gilroy-Bold;
  font-size: 1.5em;
  font-weight: initial;
}

.text2 {
  font-family: Gilroy-Regular;
  font-size: 1.5em;
  font-weight: initial;
}

.text3 {
  font-family: Gilroy-Regular;
  font-size: 1.5em;
  font-weight: initial;
  margin-bottom: 20px;
}

.text4 {
  font-family: Gilroy-Regular;
  color: $primaryColor;
  font-size: 1.2em;
  font-weight: initial;
}

@media (max-width: 600px) {
  .welcomeTitle {
    display: flex;
    flex-direction: column;
    font-size: 4vh;
    margin-bottom: 3.25vh;
  }
  .centerContainer {
    margin-top: 3vh;
  }
  .centerUser {
    font-size: 3vh;
  }
  .textContainer {
    width: 95%;
    margin: auto;
  }
  .text1 {
    font-size: 2.5vh;
    line-height: 3.25vh;
  }
  .text2 {
    font-size: 2.5vh;
    line-height: 3.25vh;
  }
  .title2 {
    font-size: 1.5rem;
    width: 75%;
    margin: auto;
    line-height: 1.7rem;
  }
  .subtitle {
    font-size: 1rem;
  }
}
