@import "../../styles/variables.scss";

.dateContainer {
  position: relative;
}

.datePickerInput div fieldset {
  border: 1px solid $otpInputsColor;
  border-radius: 11px;
}

.datePickerInput div input {
  color: $loginInputsColor;
  padding: 5px;
}
.datePickerInput > div > input {
  text-transform: uppercase !important;
}

.datePickerInput div fieldset:focus-visible {
  border-color: $otpInputsColor;
}

.icon {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 22%;
  right: 12%;
  color: $iconsBlueColor;
  svg {
    width: 100%;
    height: 100%;
  }
}

.iconDisabled {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 22%;
  right: 12%;
  color: $loginInputsColor;
  svg {
    width: 100%;
    height: 100%;
    color: red;
  }
}

.img {
  width: inherit;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: none;
  -webkit-appearance: none;
}

input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px 10px;
  border: 1px solid $otpInputsColor;
  border-radius: 11px;
  font-size: 14px;
  color:$loginInputsColor;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease-in-out;
}


input[type="date"]:focus {
  outline: none;
  border-color: $loginInputsColor;
}


input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url('../../assets/imgs/icons/calendar.svg');
  -webkit-appearance: none;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 12px;
  height: 12px;
  opacity: 0.8;
  cursor: pointer;
}
