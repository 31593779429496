@import "../../styles/variables.scss";

.addButtonContainer {
  display: flex;
  gap: 7px;
  align-items: center;
  color: $buttonColor;
  font-family: "Gilroy-Bold";
  margin: 20px 0px;
}

.addButton {
  cursor: pointer;
  width: 33px;
  height: 35px;
}
