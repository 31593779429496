@import "../../styles/variables.scss";

.updateDataContainer {
  display: flex;
  flex-direction: column;
  width: 15%;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 124px;
}

.patientCard {
  color: $loginInputsColor;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  border-radius: 16px 0 16px 16px;
  border-spacing: 0;
  height: 30%;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.container {
  width: 85%;
}

.nextScales {
  position: absolute;
  top: -50px;
  width: 205px;
  height: 50px;
  right: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $textColorLightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2vh;
  justify-content: space-between;

  .scalesButton {
    position: absolute;
    top: -40px;
    right: 0;
    border: 1px solid;
    border-color: transparent;
    padding: 11px;
    border-radius: 16px 16px 0 0;
    background-color: $textColorLightBlue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $textColorWhite;
    font-family: Gilroy-Bold;
    font-weight: initial;
  }

  .imgCalendar {
    width: 14px;
    height: 14px;
  }

  .patientName {
    font-weight: lighter;
    font-family: "Gilroy-Bold";
    color: $primaryColor;
    display: flex;
    flex-direction: row;
    align-items: center;
    .deceasedIcon{
      position: relative;
    }
    .patientLabel {
      margin-right: 10px;
    }

    .patientDocument {
      display: flex;
      flex-direction: row;
      color: $buttonColor;
      font-size: 18px;
      font-family: Gilroy-Regular;
      gap: 10px;
    }
  }
}

.rowContainer {
  display: grid;
  grid-template-columns: 20% 20% 60%;
  gap: 5px;
  width: 100%;
  div {
    h3 {
      font-weight: initial;
      font-size: 14px;
    }
    p {
      font-family: Gilroy-Regular;
      font-size: 14px;
    }
  }
}

.lowerCaseOption {
  text-transform: lowercase;
}

.policy {
  font-family: Gilroy-Bold;
  color: #3a5db7;
  font-weight: lighter;
}

.linkPolicy {
  color: #3a5db7 !important;
  text-decoration: none;
}

.btn {
  align-self: flex-end;
}

.wideCell {
  display: flex;
  flex-direction: row;
  gap: 5px;
  p {
    word-break: break-word;
  }
}
.optionUserTitle {
  font-family: "Gilroy-Bold";
}

.infoNextScales {
  font-size: 18px;
  font-family: Gilroy-Regular;
  color: $loginTitleColor;
  margin-bottom: 5px;
}



@media (max-width: 1024px) {
  .rowContainer {
    grid-template-columns: 18% 18% 64%;
  }

  .patientCard {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .updateDataContainer {
    align-items: flex-start;
  }
  .patientCard {
    flex-direction: column;
    gap: 10px;
  }
  .rowContainer {
    grid-template-columns: 60% 40%;
  }
}
@media (max-width: 600px) {
  .nameContainer {
    flex-direction: column;
  }
  .patientName {
    order: 2;
  }
  .rowContainer {
    grid-template-columns: 100%;
  }
}
@media (max-width: 1365.9px) {
  .patientName {
    font-size: 20px;
  }
}
@media (min-width: 1366px) {
  .patientName {
    font-size: 25px;
  }
}
