@import "../../styles/variables.scss";

.messageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 16px;
    margin: auto;
}

.messageText {
    color: $primaryColor;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    white-space: pre;
}