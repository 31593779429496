@import "../../styles/variables.scss";

.toolboxContainer {
  display: flex;
  flex-direction: row;
  height: 45px;
  width: max-content;
  background-color: #F2F4F8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  margin-top: 8px;
  padding-top: 5px;
  div {
    height: fit-content;
  }

  div:not(:last-child) {
    border-right: 1px solid #E0E0E0;
  }
}