@import "../../styles/variables.scss";


.containerHelp {
  height: 100vh;
  width: 100vw;
  background-color: $primaryColor;
  background-image: url("../../assets/imgs/backgroundLogo_blue.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}


@media (max-width: 600px) {
  .containerHelp{
    background-image: url("../../assets/imgs/background_blue_mobile.png");
    background-size: cover;
    justify-content: start;
    padding-top: 50px;
  }
}