@import "../../styles/variables.scss";

.options {
  display: flex;
}

.text {
  font-family: Gilroy-Regular;
  font-size: 2.5vh;
  color: $loginInputsColor;
  margin: 2%;
  margin-top: 0;
}

.updateTitleCustom {
  margin-top: 0;
}

.helpTextCustom {
  margin-top: 7px;
  margin-bottom: 32px;
}


.cancelButtonCustom {
  @include modalButtonBlue
}

.nextButtonCustom {
  @include modalButtonWhite
}