@import "../../styles/variables.scss";

.cardContainer {
  width : 100%;
  height :auto;
  background-color : $textColorWhite;
  box-shadow: 0px 0px 11px rgba(120, 135, 160, 0.25);
  border: 1px solid #f5f5f5;
  border-radius: 11px;
  padding: 15px 20px;
  margin: 10px 0px;
  color: $loginInputsColor;
}

.process {
  background-color: $yellow;
  box-shadow: 0 0 11px rgba(120, 135, 160, 0.25);
}

.finished {
  background-color: $darkGreen;
  box-shadow: 0 0 11px rgba(120, 135, 160, 0.25);
}

.paused {
  background-color: $otpInputsColor;
  box-shadow: 0 0 11px rgba(120, 135, 160, 0.25);
}

.deceased{
  background: $purple;
  box-shadow: 0px 0px 7.85404px 0px rgba(0, 0, 0, 0.25);
}