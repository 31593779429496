@import "../../styles/variables.scss";

.dataProcessing {
  font-family: Gilroy-Regular;
  font-size: 14px;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px;
  background: #F5F5F5;
  border-radius: 5px;
  color: $loginInputsColor;
}

.habeasDataIcon {
  width: 15px;
  height: 15px;
}