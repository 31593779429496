@import "../../styles/variables.scss";

.banner {
  width: 100%;
  display: flex;
  height: 2vh;
  position: absolute;
  top: 8.4vh;
}

.bannerColor_1 {
  width: 20%;
  background-color: $primaryColor;
}

.bannerColor_2 {
  width: 20%;
  background-color: $loginLabelColor;
}

.bannerColor_3 {
  width: 20%;
  background-color: iconsBlueColor;
}

.bannerColor_4 {
  width: 20%;
  background-color: $green;
}

.bannerColor_5 {
  width: 20%;
  background-color: $orange;
}
