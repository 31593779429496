@import "../../styles/variables.scss";

.container {
  width: 100%;
}

.loginLabel {
  color: $labelPopUpColor;
  font-size: 12px;
}

.loginForm {
  padding: 30px 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.buttonSend {
  width: 179px;
  padding: 12px;
  box-sizing: border-box;
}

.anchorContainer {
  display: flex;
  flex-direction: column;
}

.spinnerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(120, 135, 160, 0.25);
  border-radius: 16px;
  top: 0;
  left: 0;
}

.anchor {
  font-family: Gilroy-Regular;
  font-size: 11px;
  color: $loginInputsColor;
  line-height: 15.757px;
}
