.questionContainer{
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonOptions{
  display: flex;
  justify-content:space-between;
  width: 100%;
}

.buttonOptionsEq5d {
  display: flex;
  justify-content:space-between;
  width: 100%;
}

.patientBtn {
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 80%;
  height: 3rem;
  left: 10%;
}

@media (min-width: 481px) {
  .buttonOptions {
    max-height: 6rem;
  }
}

@media (max-width: 480px) {
  .buttonOptions {
    margin-top: 15%;
  }

  .buttonOptionsEq5d {
    position: absolute !important;
    left: 10%;
    height: 3rem !important;
    width: 80% !important;
  }

  .patientBtn {
    margin-top: 0 !important;
    height: 3rem;
    // position: initial;
    position: absolute;
    width: 100%;

    left: 0;
    bottom: 25px;
  }
}

@media (min-width: 481px) and (max-width: 960px) {
 .patientBtn {
  bottom: 0px;
  height: 2rem;
  width: 100%;
  left: 0;
 }
}