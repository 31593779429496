@import "../../styles/variables.scss";

.headerContainer {
  // height: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  justify-content: flex-start;
  align-items: center;
}

.headerLabel {
  width: max-content;
  position: relative;
  user-select: none;
}

.headerInput {
  width: 95%;
}

.headerSorted {
  position: absolute;
  right: -15px;
  bottom: 2px;
}

@media (max-width: 1024px) {
  .headerContainer {
    height: 50px;
  }
}