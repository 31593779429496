@import "../../styles/variables.scss";


.templateContainer{
  display: grid;
  margin: 1% 2%;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: 40px auto;
  height: 93%;
}

.toolboxSection{
  grid-column: 1/2;
  grid-row: 1/2;
  height: 38px;
}

.filtersSection {
  grid-column: 2/3;
  grid-row: 1/2;
  height: 38px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.optionsSection {
  grid-column: 3/4;
  grid-row: 1/2;
  height: 38px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tableSection {
  grid-column: 1/4;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap:10px;
}  


