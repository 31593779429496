@import "../../styles/variables.scss";

.tableSection {
  display: flex;
  flex-direction: column;
  margin: 2%;
  margin-bottom: 0;
  height: 86%;
}

@media (max-height: 950px) {
  .tableSection {
      height: 84%;
  }
}

@media (max-height: 1300px) {
  .tableSection {
      height: 85%;
  }
}