@import "../../styles/variables.scss";

.uploadIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-family: Gilroy-Bold;
    line-height: 16px;
}

.label {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $buttonColor;

    img {
        width: 15px;
    }
}

.file,
.error {
    cursor: default;
    font-family: Gilroy-Regular;
    color: $errorColor;
}

.file {
    color: $loginInputsColor;
}

.divider {
    border-right: 1.5px solid $buttonColor;
    padding-right: 4px;
}