@import "../../styles/variables.scss";

.errorContainer {
  min-height: 16px;
  // height: 200px;
}

.errorContainerEq5d {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
}

.errorMessage {
  color: $errorColor;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Gilroy-Medium';
  font-style: italic;
}

.exclamationIcon {
  margin-right: 0.8vh;

}

.errorResponsive {
  width: 80%;
  margin: auto;
}

.errorMessResp {
  margin: 0;
}

@media (min-width: 601px) and (max-width: 960px) {
  .errorResponsive {
    width: 60%;
    margin: auto;
  }
  .errorMessResp {
    font-size: 11px;
    margin: 0;
  }
 }

@media (max-width: 600px) {
  .errorContainerEq5d {
    bottom: 7rem;
  }
}