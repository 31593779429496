@import "../../styles/variables.scss";

.container {
  border-radius: 20px;
  background-color: $textColorWhite;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 11px 0px rgba(120, 135, 160, 0.25);
  display: flex;
  flex-direction: column;
  height: 266px;
  margin: auto;
  padding: 36px auto;
  position: relative;
  width: 458px;
  color: $primaryColor;
  padding: 35px;
}

.welcomeText {
  width: 380px;
  text-align: end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.woman {
  width: 172px;
  height: 390px;
  position: absolute;
  left: -60px;
  top: -30px;
}

.title {
  text-align: right;
  font-family: Gilroy-Bold;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
}

.messageText{
text-align: right;
font-family: Gilroy-Regular;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30.13px; /* 167.391% */
}

.lightBlue {
  color: #5AC3F0;
font-family: Gilroy-Bold
}

.boldText {
  font-family: Gilroy-Bold;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: end;
  button {
    width: 179px;
    height: 40px;
  }
}