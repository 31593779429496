@import "../../styles/variables.scss";

.container {
  width: 295px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.title {
  color: $primaryColor;
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 286px;
  padding-left: 10px;
  position: relative;
  margin-top: 35px;
}

.title::after {
  content: "";
  width: 6.228px;
  height: 100%;
  background-color: $textColorLightBlue;
  position: absolute;
  top: 0;
  left: 0;
}

.optionTitle {
  color: $labelPopUpColor;
  font-family: Gilroy-ExtraBold;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.134px;
}

.radioContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 22px;
}
.label {
  font-family: Gilroy-ExtraBold;
  color: $loginInputsColor;
  font-size: 14px;
  padding-left: 7px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.radioBtn[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
}

.customRadioBtn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: arial;
  padding-bottom: 10px;
  width: 50%;
}

.customRadioBtn__showCheckbox {
  background-image: url(../../assets/imgs/icons/unCheckRadio.svg);
  border: none;
}

.customRadioBtn__show {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: cover;
}

.radioBtn:checked + .customRadioBtn__showCheckbox {
  background-image: url(../../assets/imgs/icons/checkedRadio.svg);
}

.dataOption {
  font-family: Gilroy-Regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: $loginInputsColor;
  padding-left: 30px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.buttonSend {
  width: 179px;
  padding: 12px;
  box-sizing: border-box;
}

.spinnerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(120, 135, 160, 0.25);
  border-radius: 16px;
  top: 0;
  left: 0;
}
.cardForm {
  border-radius: 20px;
  background-color: $textColorWhite;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 11px 0px rgba(120, 135, 160, 0.25);
  margin-top: 35px;
  padding: 36px auto;
  position: relative;
  width: 100%;
}



@media (max-width: 600px) {
  .container {
    width: 295px;
    justify-content: initial;
  }

  .title {
    width: 100%;
    font-size: 16px;
    text-align: initial;
    margin-top: 60px;
  }

}
