@import "../../styles/variables.scss";

.titleComponent {
  display: grid;
  grid-template-columns: 86% 12%;
  gap: 2%
}

.titleScale{
  color: $primaryColor;
  font-family: Gilroy-Bold;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
}

.headerScale{ 
  justify-self: flex-end;
}

.cardOption {
  margin-top: 20px;
  padding-bottom: 30px;
  padding-right: 10px;
  border-bottom: 1.35px solid #f2f4f8;
  border-right: 1.35px solid #f2f4f8;
  border-radius: 11px;
}


.viewScale {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: $buttonColor;
  font-size: 16px;
  img {
    width: 18px;
  }
}


.cardContainerWarning {
  width: 100%;
  height: auto;
  background-color: #f9e8eb;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12);
  border-radius: 11px;
  padding: 15px 20px;
  margin: 10px 0px;
  color: $loginInputsColor;
}


.warningAnnounce {
  ul {
    margin-left: 30px;
    margin-top: 20px;
    li {
      font-family: 'Gilroy-Regular';
    }
  }
}