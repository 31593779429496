@import "../../styles/variables.scss";

.adminScaleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 17px 34px;
  color: black;
  min-height: 80vh;
}

.headerScale {
  display: flex;
  justify-content: flex-end;
}

.titleScale {
  display: flex;
  gap: 10px;
}

.viewScale {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  color: $buttonColor;
  font-size: 2vh;
}

.tabsContainer {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.optionTab {
  width: 50%;
  border-bottom: solid;
  border-color: $otpInputsColor;
  color: $otpInputsColor;
  font-size: 16px;
  font-family: Gilroy-Bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  cursor: pointer;
}

.activeTab {
  color: $buttonColor;
  position: relative;
}

.activeTab::after {
  content: "' '";
  color: transparent;
  height: 6px;
  width: 100%;
  position: absolute;
  background-color: $primaryColor;
  position: absolute;
  bottom: -5px;
}
.buttonsContainer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 17px;
  button {
    height: 3rem;
  }
}
.buttonsFlex {
  justify-content: flex-end;
  gap: 17px;
}


@media (min-width: 1367px) {
  .adminScaleContainer{
    min-height: 88vh;
  }
}