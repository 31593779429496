@import "../../styles/variables.scss";

.backContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1%;
  margin-left: 5%;
  margin-bottom: 2%;
  position: absolute;
  left: 0;
  top: 10%;

  a {
    font-family: Gilroy-Regular;
    font-size: 2vh;
    color: $primaryColor;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.arrowBack {
  width: 2vh !important;
  height: 2vh !important;
}

.notificationsMessage {
  font-family: Gilroy-Regular;
  font-size: 2.25vh;
  color: $loginInputsColor;
  width: 70%;
  text-align: center;
  margin-top: 17vh;
  margin-bottom: 2vh;
  line-height: 2.25vh !important;

  // fix firefox multiprocess
  font-weight: initial;
}

.notificationsMessageAnchor {
  @extend .notificationsMessage;
  font-family: Gilroy-Bold;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.phoneNumber {
  font-size: 2.5vh;
  color: $textColorLightBlue;
  font-weight: initial;
}

.phoneNumberAnchor {
  font-family: Gilroy-Regular;
}

.informativeMessage {
  font-family: Gilroy-Light;
  color: $loginInputsColor;
  font-size: 13.1px;
  margin-top: 36px;
}

.otpInputs {
  display: flex;
  justify-content: space-around;
}

.otpFields {
  width: 38px;
  height: 40px;
  border: 1.751px solid #dedede;
  border-radius: 7px;
  color: $grey;
  font-family: Gilroy-Regular;
  font-size: 24px;
  text-align: center;
  outline-color: $textColorLightBlue;
}

.otpFieldsError{
  border-color: #F24E68;
}

.otherOptionAnchor {
  font-family: Gilroy-Light;
  font-size: 2vh;
  color: $loginInputsColor;
  margin-top: 2vh;
}

.errorContainer {
margin-top: 20px;
}

.error {
  color: $errorColor;
  font-size: 1.62vh;
  font-weight: bold;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: initial;
}

