@import "../../styles/variables.scss";

.npsGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 9.5px;
}

.npsOption {
  width: 9%;
  height: 59px;
  position: relative;
}

.npsValue {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Gilroy-Bold;
  color: $primaryColor;
  font-size: 18px;
}

.npsOption input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 2px solid #000;
  outline: none;
  margin-right: 5px;
}

.redColor input[type="radio"]:checked {
  background-color: #f24e6998;
  border-color: 2px solid #f24e68;
}
.yellowColor input[type="radio"]:checked {
  background-color: #ffd7729d;
  border-color: 2px solid #ffd772;
}
.greenColor input[type="radio"]:checked {
  background-color: #68ca639c;
  border-color: 2px solid #69ca63;
}

.rangesSection {
  width: 100%;
  display: flex;
  color: $grey;
  justify-content: space-between;
  font-family: Gilroy-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.745px;
  padding: 15px 5px;
}
