@import "../../styles/variables.scss";

.header {
    justify-self: flex-end;
}
.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  justify-content: center;
}

.text {
  color: $primaryColor;
  font-family: Gilroy-Regular;
  font-size: 10px;
}


@media (max-width: 1024px) {
  .text {
    font-size: 9px;
  }
}