@import "../../styles/variables.scss";

.banner {
  width: 100%;
  display: flex;
  height: 1vh;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bannerColor_1 {
  width: 20%;
  background-color: $headerLabelColor;
}

.bannerColor_2 {
  width: 20%;
  background-color: $loginLabelColor;
}

.bannerColor_3 {
  width: 20%;
  background-color: $iconsBlueColor;
}

.bannerColor_4 {
  width: 20%;
  background-color: $green;
}

.bannerColor_5 {
  width: 20%;
  background-color: $orange;
}

@media (max-width: 600px) {
  .banner {
    height: 1rem;
  }
}
