@import "../../styles/variables.scss";

.container {
  margin-top: 14px;
}

.tablesContainer {
  width: 96%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 90px;
}

.rowContainer {
  cursor: pointer;
}

.disableOption {
  color: #c2c2c2;
  cursor: initial;
}

.rowContainer:hover {
  background-color: rgba(117, 196, 235, 0.1);
}

.closeOptopion {
  position: absolute;
  width: 20px;
  background-color: transparent;
  border: none;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.editContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.editText {
  text-decoration: underline;
  cursor: pointer;

}

.tooltipStyle {
  margin-left: 0;
  left: -140px;
  top: -20px;
  background-color: #F2F4F8;
  min-width: unset !important;
  padding: 12px !important;
  border-radius: 6px;

  p {
    color: #001698;
    font-family: Gilroy-Regular;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }
}

.restoredTooltip {
  img {
    height: 9px;
    width: 9px;
  }
}

.deletedPlan {
  cursor: default;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.restoreContainer {
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.restorePlan {
  color: #009DAC;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@media (max-width: 1365.9px) {
  .editText {
    font-size: 12px;
  }
}

@media (min-width: 1366px) {
  .editText {
    font-size: 16px;
  }
}