@import "../../styles/variables.scss";

.updateConfirmationContainer {
  display: flex;
  flex-direction:column;
  width: 100%;
  justify-content:center;
  align-items: center;
  height: 100%;
  min-height: 100%;
}

.updateTitle {
  font-size: 35px;
  color: $primaryColor;
  text-align: center;
  white-space: pre-line;
  font-weight: initial;
}