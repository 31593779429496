@import "../../styles/variables.scss";

.header {
  background-color: $textColorWhite;
  display: flex;
  height: 71px;
}

.main {
  background-color: $textColorWhite;
  height: calc(100vh - 71px);
  display: flex;
  flex-direction: row;
}

.navbarContainer {
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.NavWidthDrop {
  width: 20%;
}

.navWidth {
  width: 80px;
}

.mainContent {
  width: calc(100vw - 80px);
  // width: 95%;
  flex-grow: 1;
  overflow: auto;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mainContentMove {
  // width: 80%;
  width: calc(100vw - 20%);
  flex-grow: 1;
  overflow: auto;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.footer {
  height: 4vh;
}


@media (min-width: 1367px) {
  .navWidth {
    width: 130px;
  }
  .mainContent {
    width: calc(100vw - 130px);
  }
}

@media (max-width: 1024px) {
  .header{
    height: 54px;
  }
  .main {
    height: calc(100vh - 54px)
  }
  .NavWidthDrop {
    width: 222px;
  }

  .navWidth {
    width: 80px;
  }
  .mainContent {
    width: calc(100vw - 80px);
  }

  .mainContentMove{
    width: calc(100vw - 222px);
  }

}