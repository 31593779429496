@import "../../styles/variables.scss";

.thermoCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.thermoCardPat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.uList {
    color: $loginInputsColor;
    font-family: Gilroy-Regular;
    font-size: 16px;
    font-weight: initial;
}

.uListPat {
    color: $loginInputsColor;
    font-family: Gilroy-Regular;
    font-size: 12px;
    font-weight: initial;
}

.labelMobile {
    font-size: 14px;
    font-family: Gilroy-Regular;
}

@media (max-width: 600px) {
    .uListPat {
        display: none;
    }

    .thermoCardPat {
        flex-direction: column;
    }
}

@media (max-width: 960px) {
    .uList {
        font-size: 11px;
    }
}

@media (min-width: 601px) and (max-width: 960px) {
    .uListPat {
        font-size: 10px;
    }
}