@import "../../styles/variables.scss";

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.previousPageDisabled {
  @include btnPagination;
  color: rgba(222, 222, 222, 1);
  .arrow {
    color: rgba(222, 222, 222, 1);
    width: 70%;
  }
}

.previousPage {
  @include btnPagination;
  color: rgba(0, 157, 172, 1);
  cursor: pointer;
  .arrow {
    color: rgba(0, 157, 172, 1);
    width: 70%;
  }
}

.infoPage {
  color: $loginInputsColor;
  align-self: center;
  text-align: center;
  display: flex;
  align-items: center;
}

.actualPage {
  @include numberPage;
  color: white;
  background-color: $primaryColor;
}

.inputPagination {
  @include inputPag;
}

.inputPaginationSmall {
  @include inputPag;
  font-size: 12px !important;
}

.tx {
  font-family: Gilroy-Regular;
  font-size: 2vh;
  margin: 0 2vh;
}

.totalPages {
  @include numberPage;
  color: $primaryColor;
}

.totalPagesSmall {
  @extend .totalPages;
  font-size: 12px !important;
}

.nextPageDisabled {
  @include btnPagination;
  color: rgba(222, 222, 222, 1);
  .arrow {
    color: rgba(222, 222, 222, 1);
    width: 70%;
  }
}

.nextPage {
  @include btnPagination;
  color: rgba(0, 157, 172, 1);
  cursor: pointer;
  .arrow {
    color: rgba(0, 157, 172, 1);
    width: 70%;
  }
}
