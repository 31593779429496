.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
}

.title {
    color: #001698;
    font-family: Gilroy-Bold;
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    text-align: center;
}

.selectInput {
    display: flex;
    gap: 4px;
    
    select {
        background-image: url(../../assets/imgs/icons/arrowSelect_blue.svg);        
        background-size: 18px;
        background-position-x: 98%;
        border: 2px solid #DEDEDE;
        border-radius: 16px;
        height: 48px;
        outline-color: transparent;
        padding: 0 12px;
    }
}