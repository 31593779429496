@import "../../styles/variables.scss";

.accordion {
  background: $textColorLightBlue;
  border-radius: 11.424px;
  box-shadow: 0px 0px 7.8px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 20px;
}

.accordion::before {
  height: 0 !important;
}

.card {
  padding: 0px;
  width: 100%;
  background-color: $textColorLightBlue;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;

}

.cardOption {
  margin-top: 20px;
  padding-bottom: 30px;
  padding-right: 10px;
  border-bottom: 1.35px solid #f2f4f8;
  border-right: 1.35px solid #f2f4f8;
  border-radius: 11px;
}

.sectionContainer {
  background: $textColorLightBlue !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 11px !important;
  padding: 22px !important;
  padding-bottom: 20px;
  box-sizing: border-box;
  font-family: "Gilroy-Bold";
  font-size: 2vh;
  color: textColorWhite;
  display: flex;
  justify-content: space-between;
  position: relative;
  div {
    margin: 0px !important;
  }
  svg {
    color: white;
    border: 2px solid white;
    border-radius: 50%;
  }
}

.marginStyle{
  margin-top: 60px;
  border-top: 1px solid #DEDEDE;
}

.marginBottom {
  margin-bottom: 60px;
}