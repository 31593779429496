// -- Colors --
$mainBlue: #001DCB;
$primaryColor: #001698;
$difusedPrimaryColor: #6f7bc1;
$primaryOpacityColor: #0e1e73e6;
$headerLabelColor: #273686;
$textColorWhite: #FFFFFF;
$loginTitleColor: #7887a0;
$loginLabelColor: #75c4eb;
$loginInputsColor: #788591;
$otpInputsColor: #dedede;
$textColorLightBlue: #5ac3f0;
$errorColor: #f24e68;
$buttonColor: #009DAC;
$labelPopUpColor: #4697ba;
$iconsBlueColor:#6bbbc5;
$grey: #65717B;
$filterLabelModal: #59616a;
$green : #abca57;
$darkGreen: #a1d23f;
$lightGreen: #EAF5D4;
$yellow:#ffd772;
$orange: #e16e5f;
$greyAccordion: #F2F4F8;
$lightGrey:#F5F5F5;
$purple: #6F44FF;
$darkText: #283645;
$lightRed: #F2BEB7;
$lightYellow: #FFE5A6;
// -- imgs --
$onboardingImg: url(../../assets/imgs/onboarding_img.jpg);
$onboardingRespImg: url(../../assets/imgs/onboardingResp_img.png);
$loginPatientsImg: url(../../assets/imgs/loginPatients_img1.jpg);
$otpPatientsImg: url(../../assets/imgs/otpPatients_img.jpg);
$managerLoginImg: url(../../assets/imgs/managerLogin_img.jpg);
$logoFsfbBackground: url(../../assets/imgs/WelcomeViewBackground.png);
$backgroundPatients: url(../../assets/imgs/img_registro_background.png);
$downloadSummaryImg: url(../../assets/imgs/download_summary.png);
$adminLoginImg: url(../../assets/imgs/adminLogin_img.jpg);


// -- Mixin --
// Revisar la edicion del componente de MUI para evitar el !important
@mixin toggleButtonCustomized {
    font-family: Gilroy-Bold !important;
    font-size: 2vh !important;
    width: 100% !important;
    height: 6.25vh !important;
    text-transform: none !important;
    padding-left: 12px !important;
    line-height: 0 !important;
    letter-spacing: 0 !important;
    border-radius: 15px 0 0 15px !important;
    border-color: transparent !important;
    text-decoration: none;
    display: flex;
    align-items: center;
}

@mixin profileButtons {
    font-size: 2vh !important;
    text-transform: none !important;
    padding-left: 1.63vh !important;
    width: 28.5vh !important;
    height: 6.25vh !important;
    line-height: 0 !important;
    letter-spacing: 0 !important;
    border-bottom: 4px solid;
    background-color: white !important;
    text-decoration: none;
    display: flex;
    align-items: center;
}

@mixin btnPagination {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px;
    margin-left: 4vh;
    margin-right: 4vh;
    height: 4vh;
    width: 4vh;
}

@mixin numberPage {
    border: solid 1px;
    border-radius: 12px;
    width: 5vh;
    height: 5vh;
    padding: 1vh 3px;
    font-size: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
    
}

@mixin inputPag {
    font-family: Gilroy-Bold;
    border: none;
    background: none;
    text-align: center;
    color: white;
    font-size: 16px;
    -moz-appearance: textfield;
    border-bottom: solid;
    border-width: 1px;
    width: 100%;
    
}

@mixin description {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

@mixin cell {
    text-align: left;
    font-family: Gilroy-Regular;
    color: $loginInputsColor;
    font-size: 2vh;
    height: 29px;
    @media (max-width: 1000px) {
        font-size: 12px;
    }
    @media (max-width: 1024px) {
        height: 27px;
    }
    @media (min-width: 1000.1px) and (max-width: 1365.9px) {
        font-size: 12px;
    }
    @media (min-width: 1366px) {
        font-size: 16px;
    }
}

@mixin accordionHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // gap: 20px;
    height: 62px;
    align-items: center;
    color: $textColorWhite;
    padding: 0 20px;
    box-shadow: 0 0 11px rgba(120, 135, 160, 0.25);
    border-radius: 11px;
}

@mixin modalButtonBlue {
    color: $textColorLightBlue;
    background-color: $textColorWhite;
    border: 1px solid;
}

@mixin modalButtonWhite {
    background-color: $buttonColor;
    color: $textColorWhite;
}
