@import "../../styles/variables.scss";

.container {
  font-family: Gilroy-Bold;
  color: $textColorLightBlue;
  font-size: 11.3px;
  display: flex;
  justify-content: center;
  gap: 7px
}

.buttonTimer {
  border: none;
  background-color: white;
  font-family: Gilroy-Bold;
  font-size: 11.3px;
  color: $primaryColor;
  cursor: pointer;
  text-decoration: underline;
}

.buttonTimerOff {
  @extend .buttonTimer;
  color: $otpInputsColor;
  cursor: not-allowed;
  pointer-events: none;
}

.confirmation {
  font-family: Gilroy-Regular;
  color: $textColorLightBlue;
  font-size: 11.3px;
  line-height: 1.5;
  text-align: center;
}

.confirmationError {
  font-family: Gilroy-Regular;
  color: $errorColor;
  font-size: 11.3px;
  line-height: 1.5;
}

// @media (max-width: 600px) {
//   .container {
//     font-size: 0.8rem;
//     padding-bottom: 10px;
//   }
//   .buttonTimer {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//   }
//   .timer {
//     margin-right: 2%;
//   }
// }
