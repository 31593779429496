.progressBarContainer {
  width: 100%;
}

.progressBar {
  height: 12px !important;
  border-radius: 6px !important;
  background-color: #edf8fd !important;
}

.progressBar span {
  background-color: #00ccdf !important;
}

@media (min-width: 601px) {
  .progressBarContainer {
    margin-bottom: 2%;
  }
}

@media (max-width: 600px) {
  .progressBarContainer {
    margin-bottom: 3%;
    margin-top: 3%;
  }
}
