@import "../../styles/variables.scss";

.listContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding-bottom: 18px;
}

.listContainer:first-of-type {
  margin-top: 10px;
}

.subTitle {
  color: $textColorLightBlue;
  width: 15%;
}

.pillinputContainer {
  width: 80%;
  position: relative;
}

.errorContainer {
  position: absolute;
  bottom: -17px;
  left: 0;
  width: 100%;
}