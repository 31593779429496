@import "../../styles/variables.scss";

.helpText {
  color: $primaryColor;
  font-family: Gilroy-Bold;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.757px;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  text-align: center;
  margin-top: 24px;
  border: none;
  background-color: transparent;
}
