@import "../../styles/variables.scss";

.sendButtonContainer {
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.buttonStart {
  width: 134px;
  background: $buttonColor;
  border-radius: 12px;
  border: none;
  color: white;
  cursor: pointer;
  height: 39px;
  font-family: Gilroy-Regular;
  font-size: 14px;
  line-height: 17px;
}