@import "../../styles/variables.scss";

.equipmentContainer {
    background: #EDE8FD;
    border-radius: 0 0 12px 12px;
    position: relative;
    top: -20px;
    padding: 30px 18px 20px;
    z-index: 1;
    box-shadow: 0px 0px 7.85px 0px #00000040;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-family: Gilroy-Regular;
    font-size: 12px;
    line-height: 14px;
    color: $loginInputsColor;
    margin-bottom: -16px;
}

.equipmentSectionComment,
.equipmentSection {
    display: flex;
    gap: 4px;
    align-items: center;
}

.equipmentSectionComment {
    align-items: start;
}

.fieldName {
    font-family: Gilroy-Bold;
    line-height: 16px;
}

.fieldValue {
    color: $loginInputsColor;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 260px;
    margin: auto;
    font-family: Gilroy-Regular;
    font-size: 14px;
    line-height: 17px;
    color: $loginInputsColor;
    width: 655px;
}

.modalTitle {
    color: $primaryColor;
    font-family: Gilroy-Bold;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 8px;
}