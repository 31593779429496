@import "../../styles/variables.scss";

.actionButton {
  background-color: transparent;
  cursor: pointer;
  border: none;
  max-height: 20px;
  display: flex;
  justify-content:center;
  align-items:center;
  color: $buttonColor;
  font-size: 14px;
  font-family: 'Gilroy-bold';
  gap: 5px;
}

.actionButton img {
  width: 16px;
  height: 16px;
}