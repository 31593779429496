@import "../../styles/variables.scss";

.rowContainer {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.rowContainer:hover {
  background-color: rgba(117, 196, 235, 0.1);
}

.frequencyDays {
  font-size: 12px;
  line-height: 16px;
}

.frequencyPeriod {
  color: #DEDEDE;
  font-size: 10px;
  line-height: 18px;
}
