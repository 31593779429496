@import "../../styles/variables.scss";

.btnSelected {
  color: $textColorLightBlue !important;
  border-bottom-color: $primaryColor !important;
  font-family: Gilroy-Bold !important;
  @include profileButtons;
}

.btnDeselect {
  color: $loginInputsColor !important;
  border-bottom-color: $otpInputsColor !important;
  font-family: Gilroy-Regular !important;
  @include profileButtons;
}

.title {
  font-size: 4vh;
  color: $primaryColor;
  line-height: 4vh !important;
  // fix firefox multiprocess
  font-weight: initial;
}

.form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.loginLabel {
  color: $textColorLightBlue;
  font-family: Gilroy-Bold;
  font-size: 2vh;
  margin-bottom: 2vh;
  margin-top: 4vh;
  max-width: 240px;
  span {
    font-family: Gilroy-Bold;
    font-weight: initial;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
}
.topContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4vh;
}

.deleteButton {
  align-items: baseline;
  background-color: transparent;
  border: none;
  color: $errorColor;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  gap: 5px;
  max-width: 400px;
}

.nameContainer {
  max-width: 240px;
}  

.permissionsList {
  max-width: 400px;
  display: grid !important;
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  gap: 10px;
  color: $loginInputsColor;
  label {
    justify-content: flex-start;
  }
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5%;
  margin-top: 2vh;
  gap: 20px;
  button {
    height: 40px;
    border-radius: 12px;
    font-size: 16px;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
