@import "../../styles/variables.scss";

.parent {
  display: flex;
  align-items: center;
}

.description {
  flex-grow: 1;
}

.image {
  object-position: center;
  object-fit: contain;
  min-width: 100px;
  min-height: 100px;
}