@import "../../styles/variables.scss";

.form {
  height: 100%;
}

.title {
  font-size: 24px;
  color: #001698;
  padding-top: 9px;
  text-align: center;
  font-family: Gilroy-Bold;
  font-weight: initial;
  height: 36px;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 169px;
  margin: 0 32px;
  align-items: center;
}

.inputOption {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 40px;
  position: relative;
}

.label {
  color: $textColorLightBlue;
  font-family: Gilroy-Bold;
  font-size: 12px;
  margin-bottom: 0px !important;
  margin-left: 0 !important;
  padding-top: 12px !important;
  margin-top: 0 !important;
  padding-bottom: 5px;

  span {
    font-family: Gilroy-Bold;
    font-weight: initial;
  }
}

.classInput {
  padding: 0 !important;
  padding-bottom: 0px;
  padding-bottom: 0 !important;
  margin-left: 0 !important;
  width: 70% !important;
  border-bottom: none !important;
}

.customInputContainer {
  width: 90%;
  border-bottom: 1px solid;
  border-color: rgb(210, 217, 233);
}

.classInputSelect {
  padding: 0 !important;
  margin-left: 0 !important;
  width: 90% !important;
}

.documentOptions {
  font-family: Gilroy-Regular;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 55px;
  align-items: flex-end;
  button {
    height: 40px;
    border-radius: 12px;
    font-size: 16px;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 147px;
  }
}

.errorMessageStyle {
  margin-left: 0;
}

.updatedBy {
  color: $otpInputsColor;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.updatedBylabel {
  @extend .label;
  color: inherit;
  font-family: Gilroy-Regular;
  font-size: 14px;
}

.updatedByData {
  font-size: 14px;
  font-family: Gilroy-Regular;
}

.selectOptionContainer {
  display: flex;
}

@media (max-width: 1024px) {
  .title {
    font-size: 20px;
  }
}

