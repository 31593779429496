@import "../../styles/variables.scss";

.header {
  background-color: $textColorWhite;
  display: flex;
  height: 10%;
}

.main {
  background-color: $textColorWhite;
  height: 90%;
  display: flex;
  flex-direction: row;
  background-image: $backgroundPatients;
  background-repeat: no-repeat;
  background-position: right top;
  width: 100%;
}

.mainContent {
  width: 60%;
  max-width: 984px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content:center;
}

.scaleFormContainer {
  width: 100%;
  min-height: 479px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 0px 16px 16px 16px;
  padding: 51px 58px 33px 54px;
  box-sizing: border-box;
  position: relative;
}

.scaleFormContainerEq5d {
  width: 100%;
  min-height: 479px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 0px 16px 16px 16px;
  padding: 51px 58px 33px 54px;
  box-sizing: border-box;
  position: relative;
}

.eq5dCol {
  font-family: Gilroy-Regular;
  font-size: 12px;
  text-align: center;
  color: $loginInputsColor;
}

.eq5dColPatient {
  text-align: left;
  padding-top: 2rem;
  color: $loginInputsColor;
  font-family: Gilroy-Regular;
  font-size: 14px;
}

.buttonOptions{
  justify-content:space-between;
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 80%;
  height: 3rem;
  left: 10%;
}

@media (max-width: 600px) {
  .main {
    height: 100%;
    flex-direction: column;
  }
  .mainContent {
    width: 90%;
    height: 85%;
    justify-content: flex-start;
  }

  .scaleFormContainer {
    box-shadow: none;
    padding: 0.5rem;
    min-height: 0;
  }

  .scaleFormContainerEq5d {
    box-shadow: none;
    padding: 0.5rem;
    min-height: 0;
    height: 100vh;
  }
}

@media (min-width: 601px) {
  .scaleFormContainer {
    min-height: 0;
    padding: 5%;
    height: 70%;
    max-height: 470px;
  }

  .buttonOptions {
      max-height: 6rem;
    }
}

@media (min-width: 601px) and (max-width: 960px) {
  .scaleFormContainer {
    box-shadow: none;
    padding: 0.5rem;
    min-height: 0;
  }

  .patientBtn {
      bottom: 0px;
      height: 2rem;
      width: 100%;
      left: 0;
  }

  .buttonOptions {
    bottom: 0;
    width: 100%;
    height: 2rem;
    left: 0;
  }
}

