@import "../../styles/variables.scss";

.inputs {
  font-family: Gilroy-Regular;
  font-size: 16px;
  line-height: 18px;
  color: $loginInputsColor;
  border: none;
  border-bottom: 2px solid;
  border-color: rgba(210, 217, 233, 1);
  outline-color: $textColorLightBlue;
  width: 90%;
  padding-left: 3vh;
  // fix firefox multiprocess
  background-color: white;

  background-image: url(../../assets/imgs/icons/magnifyingGlass.svg);
  background-repeat: no-repeat;
  background-position: 4px center;
  background-size: 13px;
}


@media (min-width: 1000.1px) and (max-width: 1365.9px) {
  .inputs {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  .inputs {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .inputs {
    width: 67%;
    margin-left: 3%;
    border: 1px solid;
    border-radius: 16px;
    border-color: rgba(222, 222, 222, 1);
    font-size: 1rem;
    padding: 0.5rem;
  }
}
