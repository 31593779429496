@import "../../styles/variables.scss";

.cardContainer {
  padding-left: 20px;
}

.listContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px 30px;
  margin-top: 12px;
}

.containerPill {
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 100%;
  background: #F5F5F5;
  border-radius: 4px;

  button {
    justify-self: end;
  }
}

.pillOption {
  cursor: pointer;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 5px;
  font-size: 14px;
  padding: 4px;
  width: auto;
  line-height: 18px;
}

.titleCard {
  color: $primaryColor;
  font-family: Gilroy-Bold;
  font-size: 18px;
  line-height: 24px;
}

.name,
.document {
  color: $primaryColor;
  font-size: 11px;
  line-height: 18px;
  font-family: Gilroy-Medium;
}

.document {
  color: $loginInputsColor;
  justify-self: center;
}

.deleteButton {
  color: $textColorLightBlue;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border: none;
  padding: 0 14px;
  background-color: transparent;
}

.editContainer {
  padding: 25px 40px 20px;
  height: unset;
  cursor: default;

  >div:first-child {
    >section:nth-child(2) {
      >div {
        border-radius: 12px;
        padding: 4px 8px;
      }
    }
  }

  .saveButton {
    margin: 16px auto 0;
    width: fit-content;
  }
}

.saveContainer {
  img {
    margin-bottom: 16px;
  }

  h2 {
    font-size: 30px;
    line-height: 38px;
  }
}