.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    margin: 8% 0;

    img {
        padding: 4px 0 16px;
    }
}

.title {
    color: #001698;
    font-family: Gilroy-Bold;
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    text-align: center;
}

.subtitle {
    color: #788591;
    font-family: Gilroy-Regular;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}