@import "../../styles/variables.scss";

.container {
  color: $loginInputsColor;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin: 0 auto;
  background-color: $textColorWhite;
  border-radius: 16px;
  box-shadow: 0 0 15px rgba(120, 135, 160, 0.5);
  border-spacing: 0;
  padding: 18px;
  row-gap: 15px;
}

.title {
  font-size: 3vh;
  color: $loginTitleColor;
  font-weight: initial;
}


.questionOptions{
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
}


.label {
  color: $loginInputsColor;
  font-family: Gilroy-Bold;
  font-size: 14px;
  width: 265px;
}

.selectOption {
  width: 210px;
  position: relative;
}



// @media (max-width: 1024px) {
//   .container {
//     padding-top: 44px;
//   }
//   .questionOptions{
//     min-height: 35px;
//     margin-left: 31px;
//   }
//   .label {
//     width: 200px;
//     font-size: 12px;
//   }
// }


