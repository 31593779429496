@import "../../styles/variables.scss";

.questionsContainerOption{
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 9px;
  // border-top: 1px solid #DEDEDE;

}

.listOptionsButtons {
  display: flex;
  gap: 9px;
  padding-top: 6px;
}

.addQuestion {
  color: $otpInputsColor;
  font-size: 14px;
  font-family: Gilroy-Regular;
  font-style: normal;
}

.sectionButton {
  background-color: $primaryColor !important;
  color: white !important;
  border: 1px solid !important;
  border-radius: 11px !important;
  border-color: transparent !important;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12) !important;
  height: 34px !important;
}

.optionButton {
  border: 1px solid !important;
  border-radius: 11px !important;
  border-color: transparent !important;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12) !important;
  height: 34px !important;
}

