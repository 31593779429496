@import "../../styles/variables.scss";

.container {
  width: 295px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.infoText {
  color: $primaryColor;
  font-family: Gilroy-Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 286px;
}


.title {
  @extend .infoText;
  padding-left: 10px;
  position: relative;
}

.title::after {
  content: "";
  width: 6.228px;
  height: 100%;
  background-color: $textColorLightBlue;
  position: absolute;
  top: 0;
  left: 0;
}

.doctor {
  position: absolute;
  top: -35px;
  right: -270px;
  width: 330px;
}

.cardForm {
  border-radius: 20px;
  background-color: $textColorWhite;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 11px 0px rgba(120, 135, 160, 0.25);
  margin-top: 35px;
  padding: 36px 15px;
  position: relative;
  width: 100%;
}


@media (max-width: 600px) {
  .infoText {
    margin-top: 60px;
  }
  
}