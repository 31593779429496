@import "../../styles/variables.scss";

.parent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.content {
  width: 675px;
}

.space {
  height: 30px;
}

.descriptionWrapper {
  padding: 0 80px 0 20px;
}

.image {
  height: 475px;
  width: 290px;
}

.title {
  font-family: 'Gilroy-Bold';
  text-align: center;
  font-size: 45px;
  line-height: 55px;
  color: $primaryColor;
  margin-bottom: 48px;
}

.subtitle {
  font-family: 'Gilroy-Bold';
  text-align: center;
  font-size: 21px;
  line-height: 31px;
  color: $loginInputsColor;
  margin-bottom: 38px;
}

.text {
  font-family: 'Gilroy-Regular';
  font-size: 21px;
  line-height: 31px;
  color: $loginInputsColor;
}

.buttonContainer{
  margin-top: 10px;
  width: 100%;
 display: flex;
 justify-content: center;
}

@media screen and (max-width: 600px) {
  .image {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 46px;
  }
  
  .subtitle {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 36px;
  }
  
  .space {
    height: 20px;
  }

  .descriptionWrapper {
    padding: 0 110px 0 20px;
  }

  .text {
    font-size: 20px;
    line-height: 24px;
  }
}