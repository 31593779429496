@import "../../styles/variables.scss";

.formContainer {
  width: 96%;
  margin: 0 auto;
}

.buttonOption {
  align-self: flex-end;
}

.buttonOptions {
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: column;
  padding: 10px 47px 18px 47px;
  margin: 0 auto;
  justify-content: flex-end;
  background-color: $greyAccordion;
}
.buttons{
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}
.scaleName {
  color: $primaryColor;
  text-align: right;
  font-family: Gilroy-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.patientName {
  color: $textColorLightBlue;
  text-align: right;
  font-family: Gilroy-Bold;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.btn {
  font-family: Gilroy-Medium;
  font-size: 1.8vh;
  text-align: center;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 2vh 0;
  margin-top: 1.6vh;
  margin-bottom: 1.6vh;
  width: 20vh;
  font-weight: initial;
}

.saveBtn {
  color: $textColorLightBlue;
  background-color: $textColorWhite;
  border: 1px solid;
}

.sendButton {
  color: $textColorWhite;
  background-color: $buttonColor;
  border: none;
}

.disabledButton {
  background-color: $loginInputsColor;
  color: $textColorWhite;
}

.hiddenButtons {
  display: none !important;
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.card {
  padding: 0px;
  margin: auto;
  margin-bottom: 40px;
  background-color: $greyAccordion;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
}

.titleAccordion {
  line-height: 54px;
  padding-left: 31.5px;
  font-size: 20px;
  color: $primaryColor;
  font-weight: lighter;
}

.arrowAccordion {
  right: 14px;
  top: 16px;
}

@media (max-width: 1024px) {
  .card {
    margin-bottom: 36px;
  }
}
