@import "../../styles/variables.scss";

.warningContainer {
  display: flex;
  flex-direction:column;
  width: 100%;
  justify-content:center;
  align-items: center;
}

.warningTitle {
  font-family: 'Gilroy-Bold';
  font-size: 30px;
  text-align: center;
  color: $loginTitleColor
}

.warningSubtitle {
  font-family: 'Gilroy-Regular';
  color: $loginInputsColor;
  text-align: center;
}

.warningSubtitleBlue {
  font-size: 35px;
  color: $primaryColor;
  text-align: center;
  white-space: pre-line;
  font-family: 'Gilroy-Bold';
}

.textContinue {
  @extend .warningSubtitle;
  font-size: 16px;
}

@media (max-width: 1024px) {
  .warningSubtitleBlue {
    font-size: 30px;
  }.textContinue {
    font-size: 14px;
  }
}