@import "../../styles/variables.scss";

.rowContainer {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.rowContainer:hover {
  background-color: rgba(117, 196, 235, 0.1);
}
