@import "../../styles/variables.scss";

.descriptionContainer {
  font-family: Gilroy-Regular;
  font-size: 14px;
  color: $loginInputsColor;
  width: 90%;
  padding: 2vh 3vh;
  margin: 0 auto;
  gap: 1%;
  border-spacing: 0;
  height: 30%;
  margin-bottom: 4vh;
}

.scaleDescription {
  margin-bottom: 3rem;
}

.scaleDescriptionPatient {
  font-size: 10px;
  font-family: Gilroy-Regular;
  align-self: flex-start;
  margin-bottom: 1rem;
  color: $loginInputsColor;
}

.eq5dLogoPatient {
  width: 8rem;
}

.eq5dContainer {
  margin: auto;
  text-align: center;
}

.eq5dColPatient {
  font-size: 12px;
  font-family: Gilroy-Regular;
}


@media (max-width: 1024px) {
  .descriptionContainer {
    width: 95%;
  }
}


@media (max-width: 600px) {
  .scaleDescriptionPatient {
    position: absolute;
    bottom: 4rem;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .scaleDescriptionPatient {
    margin-bottom: 0.3rem;
  }

  .eq5dLogoPatient {
    width: 5rem;
  }
}
