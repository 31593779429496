@import "../../styles/variables.scss";

.container {
  color: $darkText;
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  font-size: 21px;
  font-family: "Gilroy-Bold";
  line-height: 31px;
}

.headerText {
  font-size: 12px;
  font-family: "Gilroy-Regular";
  line-height: 16px;
}

.principalCards {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}