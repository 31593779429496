@import "../../styles/variables.scss";

.cardForm {
  border-radius: 20px;
  margin-top: 35px;
  padding: 36px 15px;
  position: relative;
}

.doctor {
  position: absolute;
  top: -35px;
  right: -280px;
}

@media (max-width: 600px) {
  .cardForm {
    padding: 30px 15px;
    padding-bottom: 15px;
  }
  .doctor {
    display: none;
  }
}