@import "../../styles/variables.scss";

.uptdateTitle {
  color: $primaryColor;
  font-family: "Gilroy-Bold";
  font-size: 3vh;
  text-align: center;
}

.patientInfo {
  color: $loginTitleColor;
  font-family: "Gilroy-Regular";
  font-size: 2.5vh;
}
.closeOptopion {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  width: 20px;
}

.btnUser {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $buttonColor;
  cursor: pointer;
  display: flex;
  font-family: Gilroy-Bold;
  font-size: 12px;
  justify-content: center;
  padding: 0 0.5rem;
  text-transform: none;
  gap: 2px;
  text-decoration: none;
  // position: absolute;
  // top: 0;
  // right: 0;
  &:hover {
    border: none;
  }
}

.icon {
  width: 17px;
  height: 17px;
}

@media (min-width: 1366px) {
  .btnUser {
    font-size: 16px;
  }
}