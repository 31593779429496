@import "../../styles/variables.scss";

.btnEdit {
  border: none;
  background-color: transparent;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: 13vh;
  right: 2%;
  width: 100%;
}

.headerName {
  height: 100%;
}

.headerLabel {
  width: 100%;
  height: 100%;
}

.headerSorted {
  width: 0;
  display: inline-block;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 7vh;
  align-self: center;
}

.editIcon {
  font-size: 3vh !important;
  width: 100%;
}

.btnEdit {
  border: none;
  background-color: transparent;
}

.arrowSort {
  // position: absolute;
  top: 7px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.switchContainer {
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  margin-top: 2vh;
  bottom: 0px;
  position: relative;
}

.phoneRow {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  white-space: break-spaces;
}

.mobile,
.phone {
  width: 100%;
  max-height: 80%;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  margin: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.mobile:hover,
.phone:hover {
  overflow-y: visible;
  overflow-x: hidden;
  // white-space: pre-wrap;
}

.rowContainer {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.rowContainer:hover {
  background-color: rgba(117, 196, 235, 0.1);
}


@media (max-width: 1000px) {
  .arrowSort {
    top: 3px;
  }
  .phoneRow {
    font-size: 12px;
  }
}

@media (min-width: 1000.1px) and (max-width: 1365.9px) {
  .phoneRow {
    font-size: 12px;
  }
}

@media (max-width: 1365.9px) {
  .editIcon {
    width: 80%;
  }
}

@media (min-width: 1367px) {
  .arrowSort {
    top: 50%;
    width: 0.6rem;
  }
}

@media (min-width: 2000px) {
  .arrowSort {
    top: 50%;
    width: 0.8rem;
  }
}
