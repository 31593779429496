@import "../../styles/variables.scss";

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  div[type=button] {
    margin: 0;
  }
}

.errorUser {
  justify-content: space-between;
}

.errorMessageContainer {
  display: flex;
  height: 95%;
  align-items: center;
  gap: 10px;

  img {
    width: 16px;
  }
}

.errorMessage {
  color: $errorColor;
  font-size: 14px;
}