@import "../../styles/variables.scss";

.spinner {
    margin: 0 auto;
}

.spinner2 {
    width: 30vh;
    height: 30vh
}

.logPatientImg {
    width: 15vh;
    height: 15vh
}


.tracingTable {
    position: absolute;
    width: 100%;
    height: 52vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alertDel {
    width: 403px;
    display: flex;
    justify-content: center;
}

.rolesForm {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.deleUser {
    width: 403px;
    display: flex;
    justify-content: center;
    margin: auto;
    height: 100%;
}

.deleUserImg {
    width: 50%;
    height: 100%;
}

.diligence {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}