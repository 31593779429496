@import "../../styles/variables.scss";

.updateConfirmationContainer {
  display: flex;
  flex-direction:column;
  width: 100%;
  justify-content:center;
  align-items: center;
  height: 100%;
  min-height: 100%;
}

.updateTitle {
  font-family: 'Gilroy-Bold';
  font-size: 32px;
  text-align: center;
  color: $loginTitleColor;
  font-weight: initial;
}

.updateTitleBlue {
  @extend .updateTitle;
  color: $primaryColor;
}