@import "../../styles/variables.scss";

.container {
  position: relative;
  min-width: 20px;
}

.textContainer {
  position: absolute;
  max-width: 250px;
  min-width: 200px;
  padding: 9px 12px;
  box-sizing: border-box;
  box-sizing: border-box;
  left: 15px;
  top: -20px;
  border-radius: 6px;
  font-size: 12px;
  z-index: 1;
  font-weight: 400;
  line-height: 18px;
  font-family: Gilroy-Regular;
  text-align: center;
}

.light {
  background-color: white;
  color: $primaryColor;
}
.dark {
  background-color: $filterLabelModal;
  color: $textColorWhite;
  font-family: "Gilroy-Light";
  font-weight: 100;
  font-size: 10px;
  margin-left: 5px;
}

.grey {
  background-color: $lightGrey;
  color: $grey;
  max-width: 130px;
  min-width: 100px;
}

.icon {
  width: 16px;
  height: 16px;
}

.iconBig{
  min-width: 16px;
  min-height: 16px;
}