@import "../../styles/variables.scss";

.cardContainer {
  border-radius: 8px;
  box-shadow: 2px 0px 12px 0px #0000000D;
  color: $darkText;
  cursor: default;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 58px;
  max-width: 172px;
  min-width: 131px;
  padding: 0 2px 0 0;
}

.iconContainer,
.smallIcon {
  align-items: center;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 2px;
}

.smallIcon {
  width: 37%;
}

.primary {
  background-color: $lightGreen;
}

.alert {
  background-color: $lightRed;
}

.warning {
  background-color: $lightYellow;
}

.infoContainer,
.smallInfo {
  width: 72%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1px;
  box-sizing: border-box;
}

.smallInfo {
  width: 63%;
}

.numberDetail,
.smallNumber {
  font-size: 20px;
  line-height: 24px;
  font-family: "Gilroy-Bold";
  text-align: left;
}

.smallNumber {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.detailText,
.smallDetailText {
  color: $grey;
  font-size: 12px;
  font-family: "Gilroy-Regular";
  line-height: 16px;
  text-align: left;
}

.smallDetailText {
  font-size: 10px;
  line-height: 10px;
  text-align: center;
}

.active {
  border: 1px solid $mainBlue;
  cursor: pointer;
}

.canHover:hover {
  border: 1px solid $mainBlue;
  cursor: pointer;
}

.opacity {
  opacity: 0.5;
}