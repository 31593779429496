@import "../../styles/variables.scss";

.NextButton {
  font-family: Gilroy-Medium;
  color: $textColorWhite;
  background-color: $buttonColor;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 1rem;
  margin-bottom: 1.6vh;
  margin-top: 1.6vh;
  padding: 2vh 0;
  width: 20vh;
  font-weight: initial;
}

@media (min-width: 1367px) {
  .NextButton {
    padding: 1%;
    height: 4rem;
  }

  .patientBtn {
    height: 3rem;
    border-radius: 11px;
  }
}

@media (max-width: 1024px) {
  .NextButton {
    font-size: 14px;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .patientBtn {
    border-radius: 11px;
    height: 2rem;
  }
  .NextButton {
    max-width: 10rem;
    max-height: 4rem;
    margin-top: 0;
    margin-bottom: 0;
    height: 10vh;
  }
}

@media (max-width: 600px) {
  .patientBtn {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 15vh;
  }

  .NextButton {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.tracing {
  font-size: 16px;
  height: 2.5rem;
}

.noLogin {
  font-size: 16px;
  height: 2.8rem;
  border-radius: 13px;
}

.confirmed {
  padding: 0;
  height: 6vh;
  font-size: 16px;
  width: 10rem;
}

.btnFiltersModal {
  position: inherit;
  height: 32px;
  padding: 0;
  width: 127px;
  margin: auto;
}

.modalConfirm {
  height: 33px;
  width: 131px;
  border-radius: 12px;
  padding: 0;
}
