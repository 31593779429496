@import '../../styles/variables.scss';

.detailContainer {
  padding-top: 12px;
}

.detailOptions {
  display: grid;
  grid-template-columns: 20% 80%;
  font-size: 14px;
  color: $grey;
  font-family: 'Gilroy-Regular';
  padding-top: 13px;
  
}

.boldTitle {
  font-weight: bold;
}

.buttonsContainer{
  margin-top: 25px;
  display: flex;
  gap: 17px;
  justify-content: flex-end;
}