@import "../../styles/variables.scss";


.buttonBack {
  color: $primaryColor;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  cursor: pointer;
}

.tableSection {
  
   margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.description {
  width: 100%;
  font-size: 3.8vh;
  margin-top: 3%;
  padding-left: 5%;
  margin-bottom: 2%;
  color: $loginInputsColor;
}

.btn {
  align-self: flex-end;
  margin-right: 5%;
  width: 20vh;
}

.responsibleLabelContainer {
  align-self: flex-end;
  height: 45px;
  width: 246px;
  margin-top: -25px;
  margin-right: -2.5%;
  background-color: $buttonColor;
  border-radius: 8px 0px 0px 8px;
  padding-top: 13px;
  padding-left: 35px;
  margin-bottom: 10px;
}

.responsibleLabel {
  color: $textColorWhite;
}

@media (max-width: 1024px) {
  .container {
    width: 95%;
  }
  
}