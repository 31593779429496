@import "../../styles/variables.scss";

.nextButton {
  font-family: Gilroy-Regular;
  font-size: 3.6vh;
  font-weight: initial;
}

.container {
  height: 100%;
  background-color: $primaryColor;
  background-image: $logoFsfbBackground;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.containerButton {
  margin-top: 2vh;
}

@media (max-width: 600px) {
  .container {
    justify-content: flex-start;
    flex-direction: column;
    background-image: none;
  }
  .subContainer {
    margin-top: 10vh;
  }
  .logoFsfb {
    position: absolute;
    top: -22.75vh;
    left: -22.87vh;
    width: 44.5vh;
  }
  .logoFsfb_2 {
    position: absolute;
    top: 25.25vh;
    left: -36vh;
    width: 44.5vh;
  }
  .img {
    width: 4rem;
    height: 4rem;
  }
}
