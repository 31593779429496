@import "../../styles/variables.scss";

.timeContainer {
  position: relative;
}
.datePickerInput {
  width: 150px !important;
}
.datePickerInput div fieldset {
  border: 1px solid $otpInputsColor;
  border-radius: 11px;
}

.datePickerInput div input {
  color: $loginInputsColor;
  padding: 5px;
}

.datePickerInput div fieldset:focus-visible {
  border-color: $otpInputsColor;
}

.icon {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 22%;
  right: 12%;
  color: $iconsBlueColor;
  svg {
    width: 100%;
    height: 100%;
  }
}

.iconDisabled {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 22%;
  right: 12%;
  color: $loginInputsColor;
  svg {
    width: 100%;
    height: 100%;
  }
}

.img {
  width: inherit;
}

@media (max-width: 600px) {
  .timeContainer {
    width: 75%;
  }

  .datePickerInput {
    width: 100% !important;
  }
}
