@import "../../styles/variables.scss";

.footerDescription {
  width: 100%;
  // height: 95px;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  background-color: $primaryColor;
  padding: 20px 130px;
  left: 0;
}

@media (max-width: 600px) {
  .footerDescription {
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
    padding: 0 20px;
    gap: 20px;
    background-color: transparent;
  }
}
