@import "../../styles/variables.scss";

.canvaContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 475px;
  height: 280px;
  margin: auto;
}

.imgLabel {
  font-family: Gilroy-Bold;
  font-weight: initial;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  top: 5px;
  left: 70px;
}

.canvaButtons {
  position: absolute;
  top: 5px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.canvaButtons > button{
  background-color: $textColorWhite;
  border: 1px solid $buttonColor;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content:center;
  align-items: center
}

@media (max-width: 1150px) {
  .canvaContainer {
    width: 475px
  }
}