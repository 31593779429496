@import "../../styles/variables.scss";

.cardQuestion{
  margin-top: 20px;
  
  padding-right: 10px;
  border-bottom: 1.35px solid #f2f4f8;
  border-right: 1.35px solid #f2f4f8;
  border-radius: 11px;
}

.cardTitle {
  font-family: "Gilroy-Bold";
  font-size: 16.336px;
  color: $grey;
  margin: 0;
  padding-top: 20px;
}
.cardOption {
  padding: 20px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.CardObservationOption {
  margin-top: 4vh;
  display: flex;
  justify-content: flex-end;
}

.label {
  font-family: "Gilroy-Regular";
  color: $buttonColor;
  text-decoration: underline;
  cursor: pointer;
}

.textArea {
  border: 1px solid #c4c4c4;
  border-radius: 16px;
  background-color: white;
  min-height: 137px;
  width: 100%;
  padding: 19px 15px;
  box-sizing: border-box;
  resize: none;
  font-family: "Gilroy-Medium";
  font-size: 18px;
  color: $loginInputsColor;
}

.selectContainer {
  font-family: Gilroy-Regular;
  color: $loginInputsColor;
  border: none;
  border-bottom: 1px solid;
  border-color: rgba(210, 217, 233, 1);
  padding: 0.5rem;
  outline-color: $textColorLightBlue;
  width: 100%;
  background-color: white;
  max-width: 300px;
  // Arrow
  background-image: url(../../assets/imgs/icons/arrowSelect.svg);
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.dateOption {
  display: flex;
  gap: 10px;
}

.datePickerInput div fieldset {
  border: 1px solid $otpInputsColor;
  border-radius: 11px;
}

.datePickerInput div input {
  color: $loginInputsColor;
}

.datePickerInput div fieldset:focus-visible {
  border-color: $otpInputsColor;
}

.thermoCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.uList {
  color: $loginInputsColor;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: initial;
}
.thermoInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .thermoTxt {
    font-family: Gilroy-Regular;
    font-size: 10px;
  }
}
.termometro {
  width: 87px;
  height: 300px;
  background: url(../../assets/imgs/thermometer.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
}

.slider {
  -webkit-appearance: none;
  width: 275px;
  background-color: transparent;
  position: absolute;
  transform: rotate(-90deg);
  left: -94px;
  bottom: 140.5px;
}

.slider[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 4px;
  width: 4px;
  background: rgba(120, 133, 145, 1);
  border-color: transparent;
}

.slider[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: rgba(120, 133, 145, 1);
  border-color: transparent;
}

.slider[type="range"]::-webkit-slider-runnable-track {
  margin-bottom: 6.5px;
  padding-top: 6.5px;
}
