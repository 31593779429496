@import "../../styles/variables.scss";

.scaleFormContainer {
  width: 100%;
  min-height: 479px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 0px 16px 16px 16px;
  padding: 51px 58px 33px 54px;
  box-sizing: border-box;
  position: relative;
}
.scaleFormContainer > form {
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 20px;
}

.scaleFormContainerEq5d {
  width: 100%;
  min-height: 479px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 0px 16px 16px 16px;
  padding: 51px 58px 33px 54px;
  box-sizing: border-box;
  position: relative;
}


.formTitle {
  text-align: center;
  font-family: 'Gilroy-Bold';
  margin-bottom: 65px;
  font-size: 20px;
  line-height: 24.61px;
  font-weight: initial;
}

.formTitlePosas {
  @extend .formTitle;
  height: 12vh
}

.dateTimeContainer {
  display: flex;
  gap: 20px;
}

.selectOption {
  width: 100%;
  max-width: 294px;
}

.radioButtonSection {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.optionRadioContainer {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-content: center;
}

.optionRadioContainerEq5d {
  display: flex;
  flex-direction: column !important;
}

.descriptionRadioButton {
  font-size: 14px;
}

.npsContainer{
  min-width: 700px;
}

.posasPatientDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.canvaPatientDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eq5dPatientDisplay {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerRadioScale {
  justify-content: center;
}

.uList {
  font-family: Gilroy-Regular;
  font-size: 14px;
}

@media (min-width: 601px) {
  .scaleFormContainer {
    min-height: 0;
    padding: 5%;
    height: 70%;
    max-height: 470px;
  }
  .formTitle {
    margin-bottom: 5%;
  }
  .formTitlePosas {
    margin-bottom: 5%;
  }
}

@media (max-width: 600px) {
  .scaleFormContainer {
    box-shadow: none;
    padding: 0.5rem;
    min-height: 0;
    height: 100%;
  }
  // .scaleFormContainer > form {
  //   justify-content: flex-start;
  // }
  .scaleFormContainerEq5d {
    box-shadow: none;
    padding: 0.5rem;
    min-height: 0;
    height: 100vh;
  }
  .formTitle {
    color: $loginInputsColor;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: initial;
  }
  .formTitlePosas {
    color: $loginInputsColor;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: initial;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .scaleFormContainer {
    box-shadow: none;
    padding: 0.5rem;
    min-height: 0;
  }
  // .scaleFormContainer > form {
  //   justify-content: flex-start;
  // }
  .formTitle {
    color: $loginInputsColor;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: initial;
  }
  .formTitlePosas {
    color: $loginInputsColor;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: initial;
  }
}

@media (max-width: 960px) {
  .optionRadioContainer {
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    align-items: flex-start;
    gap: 0;
  }
}
