@import "../../styles/variables.scss";

.text {
  padding: 0 7px;
  font-size: 2vh;
  line-height: 26px;
  transform: rotateY(0);
  transition: all ease-out 300ms;
  font-family: Gilroy-Bold;
  opacity: 1;
  &.selectedText {
    color: $primaryColor;
  }
  &.isDropped {
    opacity: 0;
    transform: rotateY(90deg);
    width: 0;
    padding: 0;
    height: 26px;
    overflow: hidden;
  }
}

.caret {
  margin: 5px;
  line-height: 1;
  transition: all ease-out 400ms;
  &.opened {
    transform: rotate(-180deg);
  }
  &.isSelectedCaret {
    color: $primaryColor;
  }
}
