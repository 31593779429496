.newSectionContainer {
  padding-right: 10px;
  display: grid;
  grid-template-columns: 92% 6%;
  gap: 2%;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
}

.inputContainer {
  width: 100%;
}

.newSectionActionButtons {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}
.accordionItemButton {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  justify-content: center;
  align-items: center;
  color: white;
  svg {
    width: 16px;
    height: 16px;
  }
}
