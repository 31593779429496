@import "../../styles/variables.scss";

.tableContainer {
    box-shadow: 0px 0px 10.7477px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    background-color: white;
}

.btnEdit{
    border: none;
    background-color: transparent;
}

.options{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    position: absolute;
    top: -5vh;
    right: 2%;
    width: 100%;
}

.table {
    border-radius: 16px;
    margin: 0 auto;
    border-spacing: 0;
    width: 100%;
    position: relative;
}

.tooltip {
    position: absolute;
    top: 0;
    left: 3vh;
}

.trBackground {
    background-color: transparent !important;
}

.thead {
    tr {
        height: 60px;
    }
}

.tbody {
    height: 90%;
}

.header {
    color: #000F65;
    font-family: Gilroy-Bold;
    font-size: 16px;
    width: 25%;
    height: 100%;

    // fix firefox multiprocess
    font-weight: initial;

}

.headerName {
    height: 100%;
}

.headerLabel {
    width: 100%;
}

.headerSorted {
    width: 0;
    display: inline-block;
}

.cells {
    @include cell;
    max-width: 1px;
  }

.row {
    height: 10%;
}

.row:nth-child(odd) td {
    background-color: $greyAccordion;
}

.row:last-child td:first-child {
    border-radius: 0 0 0 16px
}

.row:last-child td:last-child {
    border-radius: 0 0 16px 0px
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    position: absolute;
    bottom: 7vh;
    align-self: center;
}

.paginationPat, .paginationHis {
    @extend .pagination;
    bottom: 4vh;
}

.previousPageDisabled {
    @include btnPagination;
    color: rgba(222, 222, 222, 1);
    .arrow {
        color: rgba(222, 222, 222, 1);
        width: 70%
    }
}

.previousPage {
    @include btnPagination;
    color: rgba(0, 157, 172, 1);
    .arrow {
        color: rgba(0, 157, 172, 1);
        width: 70%
    }
}

.nextPageDisabled {
    @include btnPagination;
    color: rgba(222, 222, 222, 1);
    .arrow {
        color: rgba(222, 222, 222, 1);
        width: 70%
    }
}

.nextPage {
    @include btnPagination;
    color: rgba(0, 157, 172, 1);
    .arrow {
        color: rgba(0, 157, 172, 1);
        width: 70%
    }
}

.infoPage {
    color: $loginInputsColor;
    align-self: center;
    text-align: center;
    display: flex;
    align-items: center;
}

.actualPage {
    @include numberPage;
    color: white;
    background-color: $primaryColor
}

.totalPages {
    @include numberPage;
    color: $primaryColor;
}

.totalPagesSmall {
    @extend .totalPages;
    font-size: 12px !important;
}

.tx {
    font-family: Gilroy-Regular;
    font-size: 2vh;
    margin: 0 2vh;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    color: $primaryColor;
    font-size: 2.6vh;
}

.loginForm {
    display: flex;
    flex-direction: column;
    text-align: left;
    
}

.loginLabel {
    font-size: 2vh;
    color: $labelPopUpColor;
    margin-top: 5%;
    margin-bottom: 2%;

    // fix firefox multiprocess
    font-weight: initial;

}

.loginInputs {
    font-family: Gilroy-Regular;
    color: $loginInputsColor;
    border: 3px solid;
    border-radius: 30px;
    border-color: $textColorLightBlue;
    padding: 1.75vh;
    outline-color: $textColorLightBlue;

    // fix firefox multiprocess
    background-color: white;
}

.btnsContainer {
    display:flex;
    flex-direction: row;
}

.icon {
    width: 2vh !important;
    height: 2vh !important;
    margin-right: 4px !important;
}

.modalDownload {
    font-size: 1.5vh !important;
}

.editIcon {
    font-size: 3vh !important;
}
.closeOptopion {
    position: absolute;
    width: 20px;
    background-color: transparent;
    border: none;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.spinnerContainer {
    // height: 12rem;
    height: 46vh;
}

.radioBtn[type=radio ] {
    appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
    width: 0.9rem;
    height: 0.9rem;
    position: absolute;
}

.customRadioBtn {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-family: arial;
}

.checkContainer {
    .customRadioBtn {
        .customRadioBtn__showCheckbox {
            background-image: url(../../assets/imgs/icons/unCheckRadio.svg);
        }
    }
}

.customRadioBtn__show {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: cover;
}

.radioBtn:checked + .customRadioBtn__showCheckbox {
    background-image: url(../../assets/imgs/icons/checkedRadio.svg);
}

.inputPagination {
    @include inputPag;
  }
  
  .inputPaginationSmall {
    @include inputPag;
    font-size: 12px !important;
  }
  
  .inputPagination:focus, .inputPaginationSmall:focus {
    outline: none;
  }
  
  .inputPagination::-webkit-inner-spin-button, 
  .inputPagination::-webkit-outer-spin-button,
  .inputPaginationSmall::-webkit-inner-spin-button,
  .inputPaginationSmall::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      appearance: none;
      margin: 0; 
  }

.overflow {
    max-height: 29px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    margin: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

//   .overflow:hover {
//     overflow-y: visible;
//     overflow-x: visible;
//     white-space: pre-wrap;
//   }

.checkAllBtn {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
    gap: 8px;
}

.checkAllBtn {
    .customRadioCheckbox {
      .customRadioCheckbox__showCheckbox {
        background-image: url(../../assets/imgs/icons/unCheckG.svg);
      }
    }
}

.checkContainer {
    display: flex;
    justify-content: center;
    padding-left: 0;
}

.checkContainer {
    .customRadioCheckbox {
      .customRadioCheckbox__showCheckbox {
        background-image: url(../../assets/imgs/icons/unCheck.svg);
      }
    }
}

.customRadioCheckbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-family: arial;
}

.customRadioCheckbox__show {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: cover;
}

.checkBox:checked + .customRadioCheckbox__showCheckbox {
    background-image: url(../../assets/imgs/icons/checked.svg);
}

.checkBox[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 0.9rem;
    height: 0.9rem;
    position: absolute;
}

.selectedStyle {
    position: initial;
}

@media (min-width: 1367px) {
    .radioBtn[type=radio] {
        width: 2rem;
        height: 2rem;
    }
    .checkBox[type="checkbox"] {
        width: 2rem;
        height: 2rem;
    }
    .customRadioCheckbox__show {
        width: 18px;
        height: 18px;
    }
}

@media (max-width: 1000px) {
    .actualPage {
        border-radius: 6px;
    }

    .totalPagesSmall {
        border-radius: 6px;
    }

    .header {
        font-size: 12px;
    }

    .overflow {
        max-height: 22px;
    }
}

@media (max-width: 1024px) {
    .thead {
        tr {
            height: 50px;
        }
    }
    .customRadioCheckbox__show {
        width: 14px;
        height: 14px;
    }
    .overflow {
        max-height: 27px;
    }
}

@media (min-width: 1000.1px) and (max-width: 1365.9px) {
    .header {
      font-size: 14px;
    }

    .customRadioCheckbox__show {
        width: 14px;
        height: 14px;
    }
}

  @media (max-width: 1365.9px) {
    .customRadioBtn__show {
        width: 14px;
        height: 14px;
    }
  }

@media (min-height: 900px) {
    .spinnerContainer {
        height: 56vh;
    }
}