@import "../../styles/variables.scss";

.addOption {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content:center;
  background-color:transparent;
  cursor: pointer;
  border: 1px solid;
  font-family: 'Gilroy-Bold';
  font-size: 14px;
  color: $buttonColor;
  border-radius: 10.8px;
  border-color: $buttonColor;
  padding: 8px 4px;
  margin-top: 15px;
}
.addButton{
  width:12px;
}