@import "../../styles/variables.scss";


.updateTitleCustom {
  margin-top: 0;
}

.helpTextCustom {
  margin-top: 7px;
  margin-bottom: 32px;
}

.btnsContainerCustom {
  flex-direction: row-reverse;
}

.cancelButtonCustom {
  background-color: $buttonColor;
  color: $textColorWhite;
}

.nextButtonCustom {
  color: $textColorLightBlue;
  background-color: $textColorWhite;
  border: 1px solid;
}