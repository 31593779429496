@import "../../styles/variables.scss";

.mainContainer {
  background-color: #FFFFFF;
  border: 1px solid #f5f5f5;
  border-radius: 12px;
  box-shadow: 0px 0px 11px rgba(120, 135, 160, 0.25);
  height: auto;
  width: 100%;
  margin: 10px 0;
}

.searchContainer {
  padding: 15px 40px 0;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.titleCard {
  color: $primaryColor;
  font-family: Gilroy-Bold;
  font-size: 18px;
  line-height: 24px;
}

.divider {
  border-top: 0.5px solid $textColorLightBlue;
}

.patientInfo {
  background: $greyAccordion;
  border-radius: 0 0 12px 12px;
  padding: 14px 40px;
}