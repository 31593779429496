@import "../../styles/variables.scss";

.buttonUpload {
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: 'Gilroy-Regular';
  font-size: 12px;
  line-height: 16px;
  color: $loginInputsColor;
  display: flex;
  gap: 20px;
  align-items: center;
}
.previewContainer{
  display: grid;
  margin-top:10px;
  grid-template-columns: 90% 8%;
  gap: 2%
}

.previewImage {

  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    object-fit: contain;
  }
}

.trashIcon {
  cursor: pointer;
}