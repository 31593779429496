
@import "../../styles/variables.scss";

.inputConainer {
  display: flex;
  flex-direction: column;
}

.inputLabel {
  color: $loginInputsColor;
  font-family: 'Gilroy-Bold';
  font-size: 13px;
}

.labelWhite {
  color: white;
  font-family: 'Gilroy-Bold';
  font-size: 13px;
}

.inputs {
  font-family: Gilroy-Regular;
  color: $loginInputsColor;
  border: none;
  border-bottom: 1px solid;
  border-color: rgba(210, 217, 233, 1);
  padding: 0.5rem;
  outline-color: $textColorLightBlue;
  background-color: white;
  position: relative;
   
}


.inputs::placeholder {
  color: #cecece;
  font-family: Gilroy-Regular;
}

.inputWhiteOption {
  font-family: Gilroy-Regular;
  color: white;
  border: none;
  border-bottom: 1px solid;
  border-color: rgba(210, 217, 233, 1);
  padding: 0.5rem;
  outline-color: $textColorLightBlue;
  background-color: transparent !important;
}




.inputWhiteOption::placeholder {
  color: white;
}

.input {
  position: relative;
  width: 100%;
  input {
    width: 100%;
    &:-webkit-autofill {
      background-color: transparent;
    }
  }
 
}

.custom-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.subLabel {
  position: absolute;
  right: 10px;
  top: 5px;
  color: $otpInputsColor;
  font-size:12px;
}
