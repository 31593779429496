@import "../../styles/variables.scss";


.notification {
  width: 748px;
  text-align: center;
}

.notificationTitle {
  font-family: Gilroy-Bold;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notificationText{
  color: #FFF;
font-family: Gilroy-Regular;
font-size: 23px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.iconHeart{
  width: 82px;
}

@media (max-width: 600px) {
  .notification {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

 

  .notificationTitle {
    font-size: 32px;
  }

  .notificationText{
    font-size: 20px;
  }
}