@import "../../styles/variables.scss";

.selectContainer {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  gap: 3px;
}

.buttonOption {
  color: $grey;
  font-family: Gilroy-Regular;
  font-size: 11px;
  font-style: normal;
  border-radius: 8px;
  border: 0.75px solid var(--gris-componentes, #DEDEDE);
  width: auto;
  height: 21px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  appearance: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3px;
  gap: 3px;

  -webkit-appearance: none;
  -moz-appearance: none;
}
.buttonOptionBlue {
  @extend .buttonOption;
  border: 0.85px solid $textColorLightBlue;
}

.buttonOptionSelected {
  color: $textColorLightBlue;
  font-family: Gilroy-Bold;
}

.imgContainer {
  width: 15px;
  display: flex;
  justify-content: center;
}

.dropdownContainer {
  position: absolute;
  top: 32px;
  color: black;
  width: max-content;
  max-width: 260px;
  right: 0;
  border-radius: 8px;
  background: $textColorWhite;
  box-shadow: 1px 0px 6.309999942779541px 0px rgba(0, 0, 0, 0.20);
  z-index: 1;
}

.dropdownList {
  list-style: none;
  color: $grey;
  font-family: Gilroy-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 11px 0;

  li {
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  li:hover {
    background-color: $greyAccordion;
  }
}

.filtersOptions {
  display: flex;
  flex-direction: row;
  gap: 3px;
}