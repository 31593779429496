@import "../../styles/variables.scss";

.formContainer{
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: $loginInputsColor;
}

.titleImageContainer {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 20px;
}

.optionForm {
  display: grid;
  grid-template-columns: 15% auto;
  width: 100%;
  gap:25px;
  align-items: center;
  // justify-content: space-between;
}

.imageOption{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px
}

.twoColumn{
  display: grid;
  gap: 30px;
  grid-template-columns: 30% 68%;
  margin-bottom: 20px;
}

.options{ 
  margin-top: 35px;
}

.input{
  width: 100%;
}

.threeColum {
  margin-top: 35px;
  display: grid;
  gap: 30px;
  grid-template-columns:repeat(3,  1fr);
  align-items: center;
}


.optionDiligence{
  width: 56%;
  text-align: center;
  flex-direction: column;
}

.optionDiligenceSendPatient{
  text-align: center;
  flex-direction: column;
}

.textCenter{
  text-align: center;
}

.disabled{
  display: none;
}

.show{
  display: flex;
}

.point{
  flex-direction: column;
}

.cardContainerWarning {
  width: 100%;
  height: auto;
  background-color: #f9e8eb;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.12);
  border-radius: 11px;
  padding: 15px 20px;
  margin: 10px 0px;
  color: $loginInputsColor;
}

.warningAnnounce {
  ul {
    margin-left: 30px;
    margin-top: 20px;
    li {
      font-family: 'Gilroy-Regular';
    }
  }
}

@media (min-width: 1367px) {
  .lineModule {
    display: grid;
    grid-template-columns: repeat(2,  1fr);
    gap: 25px
  }
}
