@import "../../styles/variables.scss";

.containerTable {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  height: 100%;
  background-color: white;
  border-color: transparent;
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}

.table {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  border: 1px solid;
  margin: 0 auto;
  border-spacing: 0;
  width: 100%;
  position: relative;
  border-color: transparent;
  background-color: #ede8fd;
  padding: 10px 10px;
  padding-left: 16px;
}

.header {
  color: rgba(40, 40, 40, 1);
  font-family: Gilroy-Bold;
  font-size: 12px;
  line-height: 16px;
  font-weight: initial;
  padding: 0 0 8px 0;
}

.cells {
  @include cell;
  height: 20px;
  padding: 0px !important;
  box-sizing: border-box;
}

.overflow {
  max-height: 18px;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin: auto;
}

.overflow:hover {
  max-height: 100%;
  overflow-y: visible;
  overflow-x: visible;
  white-space: pre-wrap
}
