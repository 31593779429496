@import "../../styles/variables.scss";
.card {
  padding: 0px;
  width: 100%;
  background-color: $textColorLightBlue;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;

}

.newSectionContainer {
  padding-right: 10px;
  display: grid;
  grid-template-columns: 92% 6%;
  gap: 2%;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
}

.title {
  font-size: 19px;
  font-family: 'Gilroy-Bold';
  color: $textColorWhite;
}

.accordeon{
  margin-top: -5px;
}

.accordionItemButton {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  justify-content: center;
  align-items: center;
  color: white;
  svg {
    width: 16px;
    height: 16px;
  }
}