

.cardOption {
  margin-top: 20px;
  padding-bottom: 30px;
  padding-right: 10px;
  border-bottom: 1.35px solid #f2f4f8;
  border-right: 1.35px solid #f2f4f8;
  border-radius: 11px;
}

